import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useAlert } from 'react-alert'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import { yellow } from '@material-ui/core/colors'
import { PostApi } from '../../Common/ApiAxios'

import SortableTableHeader from './SortableHeader'
import { cell, icon, WithOverTimeToolTip, overtimeReasonExists, getOvertimeReason, stableSort, getComparator } from './helper'
import TabSelector from '../../Common/Atoms/TabSelector'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: '1 1 100%'
  },
  label: {
    fontWeight: 'bold',
    fontSize: '0.8rem'
  }
}))

const searchEmpList = [
  { value: 0, name: '全データ' },
  { value: 1, name: '社員' },
  { value: 2, name: '社員以外' }
]

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles()
  // TODO props 合わせる
  const { searchEmpCond, setSearchEmpCond } = props
  const { grants, handleUpdate, title = '' } = props

  return (
    <Toolbar
      className={classes.root}
    >
      <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
        {title}
      </Typography>
      <div style={{ padding: '10px 8px 0 0' }}>
        <TabSelector
          selectValue={searchEmpCond}
          setSelectValue={setSearchEmpCond}
          selectedBackColor='#303F9F'
          title=''
          tabsName='searchEmp'
          dataList={searchEmpList}
        />

      </div>
      {
        // 書き込み権限が自分のみの場合は更新不可にしておく
        grants && (grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1)
          ? (
            <Button
              variant='contained'
              color='primary'
              aria-label='Approval'
              onClick={() => handleUpdate()}
            >
                更新
            </Button>
          )
          : null
      }
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  handleUpdate: PropTypes.func,
  title: PropTypes.string,
  grants: PropTypes.object
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: yellow[100]
    }
  },
  tableCell: {
    paddingRight: 5
  },
  customTooltip: {
    margin: '5',
    // backgroundColor: '#FFF9C4',
    // backgroundColor: '#f5f5f5',
    // color: '#000',
    fontSize: '0.8em'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  label: {
    fontSize: '0.9em',
    fontWeight: 'bold',
    align: 'center'
  }
}))

/**
 * テーブル本体
 */
export default function LaborApprovalSortTable (props) {
  const alert = useAlert()
  const { grants, overtimeInfo, headCells, rows, handleDetailClick = f => f } = props
  const classes = useStyles()
  const { loginUserInfo } = useSelector(state => state)
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('dataValue')
  const [searchEmpCond, setSearchEmpCond] = useState(0)

  const filterRows = rows => {
    if (searchEmpCond === 0) return rows

    return searchEmpCond === 1
      ? rows.filter(r => r.staffId.length === 7 && r.staffId.substring(0, 1) === '9')
      : rows.filter(r => r.staffId.length !== 7 || r.staffId.substring(0, 1) !== '9')
  }

  // 承認状態更新
  const handleUpdate = async () => {
    // 承認、承認解除状態を取得
    let approvedData = []

    // TODO replace to foreach
    // eslint-disable-next-line
    rows.map(r => {
      // 未承認→承認
      if (r.approvedDate === '') {
        if (selected.includes(r.id)) {
          approvedData = approvedData.concat({
            staffId: r.staffId,
            approvedDate: r.dateValue,
            approvedStaffId: loginUserInfo.staffId,
            createdId: loginUserInfo.staffId,
            updatedId: loginUserInfo.staffId
          })
        }
      }
    })

    // console.log('🚀 ~ file: SortableTable.js ~ line 172 ~ handleUpdate ~ selected', selected)
    if (approvedData.length === 0) {
      alert.info('更新対象がありません')
      return null
    }
    const apiUrl = '/api/labor/approved'
    let postResult = null

    if (approvedData.length > 0) {
      postResult = await PostApi(
        apiUrl,
        { approvedLabors: approvedData },
        true
      )
    }

    if ((postResult == null || postResult.status === 200)) {
      alert.success('更新しました。')
      props.setUpdateTime(Date.now())
    } else {
      alert.error('更新に失敗しました。')
    }
  }

  const [selected, setSelected] = React.useState([])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (name) => () => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    const selectedItems = rows
      .filter(r => r.approvedDate !== '')
      .map(a => a.id)
    setSelected(selectedItems)
  }, [rows])

  const isSelected = (id) => selected.indexOf(id) !== -1
  // console.log('🚀 ~ file: SortableTable.js ~ line 154 ~ SortableTable ~ approvedLabor', approvedLabor)
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={props.title}
          handleUpdate={handleUpdate}
          grants={grants}
          searchEmpCond={searchEmpCond}
          setSearchEmpCond={setSearchEmpCond}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size='small'
            aria-label='enhanced table'
          >
            <SortableTableHeader
              classes={classes}
              headCells={headCells}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              grants={grants}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(filterRows(rows), getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow
                      hover
                      onClick={
                        (grants &&
                        ((grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1))
                        )
                          ? handleClick(row.id)
                          : null
                      }
                      tabIndex={-1}
                      key={row.id}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                      className={classes.tableRow}
                    >
                      {
                        row && Object.keys(row).map((key, _index) => {
                          const dataHeader = headCells.filter(h => h.id === key)
                          if (dataHeader.length > 0) {
                            return (
                              <React.Fragment key={_index}>
                                {
                                // 残業があるもののみ
                                  dataHeader[0].label === '残業 申請' && row[key] !== 0
                                    ? (Object.keys(row).includes('dateValue')
                                      ? overtimeReasonExists(overtimeInfo, row.staffId, row.dateValue)
                                      : overtimeReasonExists(overtimeInfo, row.staffId))
                                      ? (
                                        <WithOverTimeToolTip
                                          classes={classes}
                                          overtimeReason={getOvertimeReason(overtimeInfo, row.staffId, row.dateValue)}
                                        >
                                          {icon(classes, _index, dataHeader[0])}
                                        </WithOverTimeToolTip>
                                      )
                                      : (
                                        <TableCell />
                                      )
                                    : (
                                      cell(classes, _index, dataHeader[0], row, key)
                                    )

                                }
                              </React.Fragment>
                            )
                          }
                        })
                      }
                      <TableCell padding='checkbox'>
                        <Checkbox
                          disabled={!(grants && (grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1))}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell padding='checkbox'>
                        {row.startDt != null &&
                          (
                            <Button
                              color='primary'
                              variant='contained'
                              data-value='aaa'
                              onClick={handleDetailClick(row.id)}
                            >
                              詳細
                            </Button>
                          )}
                      </TableCell>

                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

LaborApprovalSortTable.propTypes = {
  title: PropTypes.string,
  overtimeInfo: PropTypes.array,
  headCells: PropTypes.array,
  rows: PropTypes.array.isRequired,
  handleDetailClick: PropTypes.func.isRequired,
  staffId: PropTypes.string,
  setUpdateTime: PropTypes.func.isRequired,
  grants: PropTypes.object
}
