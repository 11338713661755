export const headCells = [
  { id: 'dateValue', numeric: false, disablePadding: true, label: '日付', width: 50 },
  { id: 'week', numeric: false, disablePadding: true, label: '曜日', width: 30 },
  { id: 'plannedStartDt', numeric: false, disablePadding: true, label: '予定 開始', width: 50 },
  { id: 'plannedEndDt', numeric: false, disablePadding: true, label: '予定 終了', width: 50 },
  { id: 'startDt', numeric: false, disablePadding: true, label: '開始', width: 80 },
  { id: 'endDt', numeric: false, disablePadding: true, label: '終了', width: 80 },
  { id: 'sumHour', numeric: true, disablePadding: true, label: '勤務 時間', width: 80 },
  { id: 'breakHours', numeric: true, disablePadding: true, label: '休憩', width: 80 },
  { id: 'overTime', numeric: true, disablePadding: true, label: '残業', width: 80 },
  { id: 'nightTime', numeric: true, disablePadding: true, label: '深夜', width: 80 },
  { id: 'overtimeReason', String: false, disablePadding: true, label: '残業 申請', width: 50 },
  { id: 'approved', numeric: false, disablePadding: true, label: '承認', width: 100 },
  { id: 'approvedDate', numeric: false, disablePadding: true, label: '承認日時', width: 180 },
  { id: 'approvedStaff', numeric: false, disablePadding: true, label: '承認者', width: 150 }
]

export const headCellsDep = [
  { id: 'staffId', numeric: false, disablePadding: true, label: 'ID', width: 50 },
  { id: 'staffName', numeric: false, disablePadding: true, label: '社員名', width: 180 },
  { id: 'plannedStartDt', numeric: false, disablePadding: true, label: '予定 開始', width: 50 },
  { id: 'plannedEndDt', numeric: false, disablePadding: true, label: '予定 終了', width: 50 },
  { id: 'startDt', numeric: false, disablePadding: true, label: '開始', width: 50 },
  { id: 'endDt', numeric: false, disablePadding: true, label: '終了', width: 50 },
  { id: 'workHours', numeric: true, disablePadding: true, label: '勤務 時間', width: 80 },
  { id: 'breakHours', numeric: true, disablePadding: true, label: '休憩', width: 80 },
  { id: 'overTime', numeric: true, disablePadding: true, label: '残業', width: 80 },
  { id: 'nightTime', numeric: true, disablePadding: true, label: '深夜', width: 80 },
  { id: 'overtimeReason', String: false, disablePadding: true, label: '残業 申請', width: 50 },
  { id: 'approved', numeric: false, disablePadding: true, label: '承認', width: 100 },
  { id: 'approvedDate', numeric: false, disablePadding: true, label: '承認日時', width: 180 },
  { id: 'approvedStaff', numeric: false, disablePadding: true, label: '承認者', width: 150 }
]

export const headCellsUnapproved = [
  { id: 'dateValue', numeric: false, disablePadding: true, label: '日付', width: 50 },
  { id: 'week', numeric: false, disablePadding: true, label: '曜日', width: 30 },
  { id: 'staffId', numeric: false, disablePadding: true, label: 'ID', width: 50 },
  { id: 'staffName', numeric: false, disablePadding: true, label: '社員名', width: 180 },
  { id: 'plannedStartDt', numeric: false, disablePadding: true, label: '予定 開始', width: 50 },
  { id: 'plannedEndDt', numeric: false, disablePadding: true, label: '予定 終了', width: 50 },
  { id: 'startDt', numeric: false, disablePadding: true, label: '開始', width: 50 },
  { id: 'endDt', numeric: false, disablePadding: true, label: '終了', width: 50 },
  { id: 'workHours', numeric: true, disablePadding: true, label: '勤務 時間', width: 80 },
  { id: 'breakHours', numeric: true, disablePadding: true, label: '休憩', width: 80 },
  { id: 'overTime', numeric: true, disablePadding: true, label: '残業', width: 80 },
  { id: 'nightTime', numeric: true, disablePadding: true, label: '深夜', width: 80 },
  { id: 'overtimeReason', numeric: false, disablePadding: true, label: '残業 申請', width: 50 },
  { id: 'approved', numeric: false, disablePadding: true, label: '承認', width: 100 },
  { id: 'approvedDate', numeric: false, disablePadding: true, label: '承認日時', width: 180 },
  { id: 'approvedStaff', numeric: false, disablePadding: true, label: '承認者', width: 150 }
]

export const headCellsOvertime = [
  { id: 'dateValue', numeric: null, disablePadding: true, label: '日付', width: 100 },
  { id: 'week', numeric: null, disablePadding: true, label: '曜日', width: 20 },
  { id: 'staffId', numeric: null, disablePadding: true, label: 'ID', width: 60 },
  { id: 'staffName', numeric: null, disablePadding: true, label: '社員名', width: 80 },
  { id: 'foreseeType', numeric: false, disablePadding: true, label: '予見種別', width: 50 },
  { id: 'causeType', numeric: false, disablePadding: true, label: '原因種別', width: 50 },
  { id: 'hours', numeric: true, disablePadding: true, label: '予定時間', width: 50 },
  { id: 'explanation', numeric: false, disablePadding: true, label: '説明', width: 150 }
]

// 氏名, 承認率, 就業日数, 公休, 推奨公休数, 有給, 代休,
// 進捗工数, 進捗残業工数, 進捗深夜工数, 残業残工数, 残深夜工数, 平均工数
export const headCellsOvertimeAlert = [
  { id: 'belong', numeric: null, disablePadding: true, label: '所属', width: 150 },
  // { id: 'staffId', numeric: null, disablePadding: true, label: 'ID', width: 60 },
  { id: 'staffName', numeric: null, disablePadding: true, label: '氏名', width: 100 },
  { id: 'approvedRate', numeric: null, disablePadding: true, label: '承認率', width: 50 },
  { id: 'workCnt', numeric: null, disablePadding: true, label: '就業 日数', width: 50 },
  { id: 'publicCnt', numeric: null, disablePadding: true, label: '公休', width: 50 },
  { id: 'recommendationPublc', numeric: null, disablePadding: true, label: '推奨 公休', width: 50 },
  { id: 'salaryCnt', numeric: null, disablePadding: true, label: '有給', width: 50 },
  { id: 'daikyuCnt', numeric: null, disablePadding: true, label: '代休', width: 50 },
  { id: 'workTime', numeric: null, disablePadding: true, label: '進捗工数', width: 50 },
  { id: 'overTime', numeric: null, disablePadding: true, label: '進捗残業工数', width: 50 },
  { id: 'nightTime', numeric: null, disablePadding: true, label: '進捗深夜工数', width: 50 },
  { id: 'zanOverTime', numeric: null, disablePadding: true, label: '残業残工数', width: 50 },
  { id: 'zanNightTime', numeric: null, disablePadding: true, label: '深夜残工数', width: 50 },
  { id: 'aveTime', numeric: null, disablePadding: true, label: '平均 工数', width: 50 }
]
