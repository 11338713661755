const LEGAL_HOURS = {
  28: { hours40: 160, hours44: 176 },
  29: { hours40: 165.7, hours44: 182.2 },
  30: { hours40: 171.4, hours44: 188.5 },
  31: { hours40: 177.1, hours44: 194.8 }
}

export const getLegalHours = (days) => LEGAL_HOURS[days].hours40

// dt => yyyy-MM
export const getDaysCountInMonth = (dt) => {
  const year = dt.substring(0, 4)
  const month = dt.substring(5, 7)
  return new Date(year, month, 0).getDate()
}

export const calcOvertime = (resultHours, days) => {
  // みなし残業30h
  const ret = (resultHours * 100 - (LEGAL_HOURS[days].hours40 + 30) * 100) / 100 // 小数点誤差対応
  return ret > 0 ? ret : 0
}
