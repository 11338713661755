/* eslint-env browser */
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Search from '../Common/Search'
// import TemplateButtons from '../Common/TemplateButtons'
import CanvasList from './CanvasList'
import OperationList from './OperationList'
import { apiGetLabors, setOperation, changeIndexByDt, getPlannedAttendance, setOpeWorkPlace, LaborStates } from './LaborSlice'
import { getLaborApproval } from './IndividualMonthly/IndividualMonthlySlice'

import RecentOperationList from './RecentOperationList'
import FavoriteOperationList from './FavoriteOperationList'
import styled from 'styled-components'
import { useAlert } from 'react-alert'
// import { GetApi, PostApi, PutApi, DeleteApi } from '../Common/ApiAxios'
import { GetApi } from '../Common/ApiAxios'
import FUNC from '../Common/FunctionEnum'
import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../Master/Common/ErrorHandling'
import TabSelector from '../Common/Atoms/TabSelector'
import SideMenu from '../Common/Molecules/SideMenu'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

const LaborDiv = styled.div`
  flex-direction: column;
  ul {
      list-style:none;
  }
  .buttons {
    flex: 0 0 50px;
    display: flex;
    justify-content: space-between;

    a {
        background: #ddd;
        margin: 20px 40px;
        padding: 10px;
        border: 1px solid #f2f2f2;
        border-radius: 4px;
        font-size: 14px;
        min-width: 54px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        text-decoration: none;
        color: black;

        :hover {
          box-shadow: 0 1px 1px rgba(0,0,0,0.1);
          background-color: #f8f8f8;
          border: 1px solid #c6c6c6;
          color: #222;
        }
    }
  }
`
const LaborGridHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  display: flex;
  z-index: 2;
`
const LaborGridHeaderLeft = styled.div`
  display: flex;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background: #555;
  position: sticky;
  left: 0;
`
const StaffName = styled.div`
  width:150px;
`
const Hours = styled.div`
  width:50px;
`
const LaborGridHeaderRight = styled.div`
  background: #555;
  height: 30px;
  margin-bottom: 3px;
`
const LaborGridBody = styled.div`
  display:flex;
`
const FlexDiv = styled.div`
  display:flex;
  flex-wrap: wrap;
`
const LaborsWrap = styled.div`
  width: calc(100vw - 20px);
  max-height: calc(100vh - 200px);
  overflow: auto;
  margin-left: 16px;
  direction: rtl;

  ::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  ::-webkit-scrollbar-track {
    :horizontal {
      margin-left: 200px;
    }
    :vertical {
      margin-top: 30px;
    }
  }

  ::-webkit-scrollbar-thumb {
    border: 3px solid #000;
    background-clip: padding-box;
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }
`

const WrapInner = styled.div`
  direction: ltr;
  width: 2420px;
`

const TotalHours = styled.div`
  color: white;
  font-size: 0.9em;
`

const ProgressRate = styled.p`
  padding-left: 16px;
  padding-top: 10px;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
`

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 30,
    width: 200,
    marginLeft: 8,
    marginTop: 4,
    borderRadius: 3
  },
  colorPrimary: {
    backgroundColor: '#CCC'
  },
  bar: {
    // borderRadius: 3,
    backgroundColor: '#649CDF'
  }
}))(LinearProgress)

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0
  },
  circle: {
    strokeLinecap: 'round'
  },
  sideMenuView: {
    display: 'block'
  },
  sideMenuHidden: {
    display: 'none'
  }
}))

const calcPercentage = (planned, result) => {
  if (planned === 0) return 0

  return Math.round((result / planned) * 1000) / 10
}
const Labor = (props) => {
  const labor = LaborStates()
  const wrapRef = useRef()
  const alert = useAlert()
  const isPlanned = props.isPlanned
  const dispatch = useDispatch()
  const [register, setRegister] = useState(1)
  const [workPlace, setWorkPlace] = useState(0)
  const [staffDivisionFilter, setStaffDivisionFilter] = useState(0)
  const [tabWorkPlace, setTabWorkPlace] = useState(() => { return labor.selectOpeWorkPlace != null ? labor.selectOpeWorkPlace : 1 })
  const tabWorkPlaceList = [
    { value: 1, name: '出勤' },
    { value: 2, name: 'リモートワーク' }
  ]
  const [companyList, setCompanyList] = useState([]) // ゾーンまでそろっている会社情報取得
  const { loginUserInfo, commonSearch, laborSlice, individualMonthly } = useSelector(state => state)
  const [totalHours, setTotalHours] = useState(0)
  const [plannedHours, setPlannedHours] = useState(0)
  const [bodyTemp, setBodyTemp] = useState([])
  const [overtimeInfo, setOvertimeInfo] = useState([])
  const [laborTabValue, setLaborTabValue] = useState(0)
  const warehouseId = commonSearch.warehouseId
  const floorId = commonSearch.floorId
  const zoneId = commonSearch.zoneId
  const workDt = commonSearch.workDate
  // const dbLabors = laborSlice.labors
  const dbLaborHours = laborSlice.laborHours
  const classes = useStyles()
  const grants = loginUserInfo.menuList &&
    loginUserInfo.menuList.find(menu => menu.functionMenuId === (isPlanned ? FUNC.PLANNED_LABOR : FUNC.RESULT_LABOR))

  const canViewOperationList = grants && (
    grants.writeGrantDivision === 2 ||
    (grants.writeGrantDivision === 1 && commonSearch.warehouseId === loginUserInfo.warehouseId) ||
    (grants.writeGrantDivision === 3 && commonSearch.warehouseId === loginUserInfo.warehouseId)
  )
  const matches = useMediaQuery('(min-width:1650px)')
  // const canWriteTemplate = grants && (
  //   grants.writeGrantDivision === 2 ||
  //   (grants.writeGrantDivision === 1 && commonSearch.warehouseId === loginUserInfo.warehouseId)
  // )

  const nowTimeIndex = commonSearch.workDate && changeIndexByDt(new Date(), commonSearch.workDate)

  // API POST テンプレート登録
  // const postTemplate = async (templateName, warehouseId, labors) => {
  //   const apiUrl = '/api/labor/template'
  //   const data = {
  //     templateName: templateName,
  //     warehouseId: warehouseId,
  //     templateLabors: labors
  //   }
  //   const result = await PostApi(apiUrl, data, true)
  //   if (result.errorDetail) {
  //     alert.error('テンプレート登録に失敗しました')
  //   } else {
  //     alert.success('テンプレートを登録しました')
  //   }
  // }

  // API PUT テンプレート更新
  // const putTemplate = async (templateId, labors) => {
  //   const apiUrl = '/api/labor/template'
  //   const data = {
  //     templateId: templateId,
  //     templateLabors: labors
  //   }
  //   const result = await PutApi(apiUrl, data, true)
  //   if (result.errorDetail) {
  //     alert.error('テンプレート更新に失敗しました')
  //   } else {
  //     alert.success('テンプレートを更新しました')
  //   }
  // }

  // API DELETE テンプレート削除
  // const deleteTemplate = async (templateId) => {
  //   const apiUrl = `/api/labor/template/${templateId}`
  //   const result = await DeleteApi(apiUrl, null, false)
  //   if (result.errorDetail) {
  //     alert.error('テンプレート削除に失敗しました')
  //   } else {
  //     alert.success('テンプレートを削除しました')
  //   }
  // }

  // API PUT テンプレート反映
  // const reflectTemplate = async (templateId, fromDate, toDate) => {
  //   const apiUrl = '/api/labor/reflectTemplate'
  //   const data = {
  //     templateId: templateId,
  //     fromDate: fromDate,
  //     toDate: toDate
  //   }
  //   const result = await PostApi(apiUrl, data, true)
  //   if (result.errorDetail) {
  //     alert.error('テンプレート反映に失敗しました')
  //   } else {
  //     alert.success('テンプレートを反映しました')
  //     dispatch(apiGetLabors(commonSearch, isPlanned, register, workPlace, nowTimeIndex, grants, loginUserInfo, staffDivisionFilter))
  //   }
  // }

  const calcTotalResultTime = () => {
    let _totalHours = 0
    if (dbLaborHours) {
      for (const l of dbLaborHours) {
        _totalHours += l.hours
      }
    }
    setTotalHours(_totalHours)
  }

  useEffect(() => {
    if (commonSearch.companyId && commonSearch.workDate && loginUserInfo.companyId) {
      const getLaborHours = async () => {
        const uri = `/api/labor/summary/monthly/${warehouseId}/${floorId}/${zoneId}/${workDt}/${workDt}`
        const { data } = await GetApi(uri)
        // const data = await ret.data
        let plannedHours = 0
        for (const s of data.staffs) {
          plannedHours += s.plannedTotalHours
        }
        setPlannedHours(plannedHours)
      }

      const getBodyTemp = async () => {
        const uri = `/api/bodyTemperature/dep/${warehouseId}/${floorId}/${zoneId}/${workDt}/${workDt}`
        const { data } = await GetApi(uri)
        setBodyTemp(data)
      }

      const getOvertimeInfo = async () => {
        const url = `/api/labor/overtime/${warehouseId}/${floorId}/${zoneId}/-/${workDt}/${workDt}`
        const result = await GetApi(url)
        setOvertimeInfo(result.data)
      }

      dispatch(SetLoadSpinner(true))// 読込時、スピナー表示
      dispatch(apiGetLabors(commonSearch, isPlanned, register, workPlace, nowTimeIndex, grants, loginUserInfo, staffDivisionFilter))
      dispatch(getPlannedAttendance(commonSearch, loginUserInfo))
      dispatch(getLaborApproval(commonSearch.floorId, commonSearch.zoneId, '-', commonSearch.workDate, commonSearch.workDate))
      getLaborHours()
      getBodyTemp()
      getOvertimeInfo()
    }
  }, [commonSearch, register, workPlace, staffDivisionFilter, loginUserInfo, window.location.href, dispatch])

  useEffect(() => {
    calcTotalResultTime()
  }, [dbLaborHours])

  const approvalData = individualMonthly?.laborApproval
  // レイバー加工
  // const ConvertLabors = () => {
  //   const result = []
  //   dbLabors.map(labor => {
  //     if (labor.operationId) {
  //       result.push(
  //         {
  //           staffId: labor.staffId,
  //           branchNo: labor.branchNo,
  //           workPlace: labor.workPlace,
  //           floorId: labor.floorId,
  //           operationId: labor.operationId,
  //           startDt: labor.startIndex * 25, // 1hを100とする
  //           endDt: labor.endIndex * 25 // 1hを100とする
  //         }
  //       )
  //     }
  //   })
  //   return result
  // }

  // テンプレート
  // 登録ボタン
  // const onCreate = (templateName) => {
  //   const convertLabors = ConvertLabors()
  //   if (convertLabors.length === 0) {
  //     alert.error('登録するレイバーがありません')
  //   } else {
  //     postTemplate(templateName, warehouseId, convertLabors)
  //   }
  // }
  // // 反映ボタン
  // const onReflect = (templateId, fromDate, toDate) => {
  //   reflectTemplate(templateId, fromDate, toDate)
  // }
  // // 更新ボタン
  // const onUpdate = (templateId) => {
  //   const convertLabors = ConvertLabors()
  //   if (convertLabors.length === 0) {
  //     alert.error('更新するレイバーがありません')
  //   } else {
  //     putTemplate(templateId, convertLabors)
  //   }
  // }
  // // 削除ボタン
  // const onDelete = (templateId) => {
  //   deleteTemplate(templateId)
  // }

  // 会社情報一覧
  const onCompanyInfoSearch = async () => {
    dispatch(SetLoadSpinner(true))
    const data = {
      notBlankStatus: 0 // 会社-倉庫-フロア-ゾーンが欠損なしのレコード
    }
    const result = await GetApi('/api/companyInfo', data)
    dispatch(SetLoadSpinner(false))
    if (ErrorHandling(result, alert)) return null
    if (result.data.length === 0) {
      alert.error('必要データが揃っていません', { onClose: () => { window.location.href = '/' } })
    }
    setCompanyList(result.data)
  }

  useEffect(() => {
    if (loginUserInfo.companyId) {
      onCompanyInfoSearch()
    }
  }, [loginUserInfo])

  useEffect(() => {
    if (commonSearch.companyId) {
      dispatch(setOperation(null))
    }
  }, [commonSearch])

  // 初期表示時のスクロールの場所
  useEffect(() => {
    if (document.getElementById('laborsWrap')) {
      const windowWidth = window.innerWidth
      const scrollDiffResult = 2420 - windowWidth + 35 - (nowTimeIndex * 15 - 20 * 15)
      document.getElementById('laborsWrap').scrollLeft = -scrollDiffResult // 5時間前より表示
    }
  }, [document.getElementById('laborsWrap')])

  useEffect(() => {
    document.title = isPlanned ? '予定工程入力 | Core First' : '実績工程入力 | Core First'
  }, [window.location.href])

  useEffect(() => {
    // レイバートグル切り替え時、読込できたらスピナーオフ
    dispatch(SetLoadSpinner(false))
  }, [laborSlice])

  // 勤怠区分設定
  useEffect(() => {
    dispatch(setOpeWorkPlace(tabWorkPlace))
  }, [tabWorkPlace])

  if (!grants) return null
  // console.log(grants)
  // console.log(workPlace)
  const registerList = [
    { value: 1, name: '全データ' },
    { value: 2, name: '登録済' },
    { value: 3, name: '未登録' }
  ]
  const workPlaceList = [
    { value: 0, name: '全データ' },
    { value: 1, name: '出勤' },
    { value: 2, name: 'リモートワーク' }
  ]

  const staffDivisionFilterList = [
    { value: 0, name: '全データ' },
    { value: 1, name: '社員' },
    { value: 2, name: '社員以外' }
  ]

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const handleChange = (event, newValue) => {
    setLaborTabValue(newValue)
  }

  const SelectFilterWrap = styled.div`
    color: #fff;
    margin: 12px 16px;
  `

  return (
    <LaborDiv>
      <Search
        title='工程入力'
        addStyle='padding: 0 0 0 16px;'
        showButton={false} showWarehouses showFloors showZones showCalendar
        addAllWarehouse addAllFloor addAllZone
        inSubCompany
        grants={grants}
        calendarType={1}
        laborToggle
        isPlanned
        inSubChildren
        showIncludeRetired
      />
      <FlexDiv>
        <TabSelector
          selectValue={register}
          setSelectValue={setRegister}
          title='登録状態'
          tabsName='registers'
          dataList={registerList}
        />
        {matches &&
          (
            <TabSelector
              selectValue={workPlace}
              setSelectValue={setWorkPlace}
              title='勤務場所'
              tabsName='workPlaces'
              dataList={workPlaceList}
            />
          )}
        <TabSelector
          selectValue={staffDivisionFilter}
          setSelectValue={setStaffDivisionFilter}
          title='ユーザー表示区分'
          tabsName='staffDivisionFilters'
          dataList={staffDivisionFilterList}
        />
        {!isPlanned &&
        (
          <>
            <div style={{ marginLeft: '8px', marginTop: '4px' }}>
              <TotalHours>予定：{plannedHours}h</TotalHours>
              <TotalHours>実績：{totalHours}h</TotalHours>
            </div>
            <div className={classes.root}>
              <BorderLinearProgress
                variant='determinate'
                value={calcPercentage(plannedHours, totalHours) > 100 ? 100 : calcPercentage(plannedHours, totalHours)}
              />
              <ProgressRate>{calcPercentage(plannedHours, totalHours)}%</ProgressRate>
            </div>
          </>
        )}
        {/* {isPlanned && canWriteTemplate &&
          <TemplateButtons onCreate={onCreate} onReflect={onReflect} onUpdate={onUpdate} onDelete={onDelete} />} */}
      </FlexDiv>
      <LaborsWrap id='laborsWrap' ref={wrapRef}>
        <WrapInner>
          <LaborGridHeader>
            <LaborGridHeaderLeft>
              <StaffName>氏名</StaffName>
              <Hours>工数</Hours>
            </LaborGridHeaderLeft>
            <LaborGridHeaderRight>
              <canvas id='timeCanvas' width='2220' height='30' />
            </LaborGridHeaderRight>
          </LaborGridHeader>
          <LaborGridBody>
            <CanvasList
              labors={laborSlice}
              approvalData={approvalData}
              isPlanned={isPlanned}
              register={register}
              workPlace={workPlace}
              grants={grants}
              nowTimeIndex={nowTimeIndex}
              wrapRef={wrapRef}
              staffDivisionFilter={staffDivisionFilter}
              bodyTemp={bodyTemp}
              overtimeInfo={overtimeInfo}
              setOvertimeInfo={setOvertimeInfo}
            />
          </LaborGridBody>
        </WrapInner>
      </LaborsWrap>
      <SideMenu isView={canViewOperationList} width='300px'>
        <AppBar position='static'>
          <Tabs style={{ height: 50, minHeight: 'auto', backgroundColor: '#0000FF' }} value={laborTabValue} onChange={handleChange} aria-label='operationSelectTab'>
            <Tab style={{ minWidth: 95, fontWeight: 'bold' }} label='工程検索' {...a11yProps(0)} />
            <Tab style={{ minWidth: 95, fontWeight: 'bold' }} label='最近の工程' {...a11yProps(1)} />
            <Tab style={{ minWidth: 95, fontWeight: 'bold' }} label='マイ工程' {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <SelectFilterWrap>
          <TabSelector
            selectValue={tabWorkPlace}
            setSelectValue={setTabWorkPlace}
            tabsName='commonOpeWorkPlace'
            dataList={tabWorkPlaceList}
            selectedBackColor='lime'
            selectedColor='black'
          />
        </SelectFilterWrap>
        <div style={laborTabValue === 0 ? {} : { display: 'none' }}>
          <OperationList companyList={companyList} loginUserInfo={loginUserInfo} />
        </div>
        <div style={laborTabValue === 1 ? {} : { display: 'none' }}>
          <RecentOperationList companyList={companyList} loginUserInfo={loginUserInfo} />
        </div>
        <div style={laborTabValue === 2 ? {} : { display: 'none' }}>
          <FavoriteOperationList companyList={companyList} loginUserInfo={loginUserInfo} />
        </div>
      </SideMenu>
    </LaborDiv>
  )
}

Labor.propTypes = {
  isPlanned: PropTypes.bool
}

export default Labor
