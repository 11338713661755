import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ListUl = styled.ul`
  width: 16.5vw;
  ${props => props.addStyle}
`

const ListLi = styled.li`
  height: 35px;
  width: 15vw;
  line-height: 35px;
  text-indent: 10px;
  background: ${props => props.selected ? '#649CDF' : '#fff'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  .listId{
    margin-right:1vw;
  }
  :nth-child(even){
    background: ${props => props.selected ? '#649CDF' : '#eee'};
  }
  :hover {
    ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
  }
`

const ListView = ({ dataList, selectedId, selectFunction, isIdView, addStyle, onMouse }) => {
  return (
    <ListUl addStyle={addStyle}>
      {dataList && dataList.map((data, index) => {
        return (
          <ListLi
            onClick={() => selectFunction(data.id)}
            selected={data.id === selectedId}
            key={index}
            value={data.id}
            onMouseEnter={() => onMouse && onMouse(index, data.name)}
            onMouseLeave={() => onMouse && onMouse(index, null)}
          >
            {isIdView ? <span className='listId'> {data.id} </span> : null}
            {data.name}
          </ListLi>
        )
      })}
    </ListUl>
  )
}

ListView.propTypes = {
  dataList: PropTypes.array,
  selectedId: PropTypes.any,
  selectFunction: PropTypes.func,
  isIdView: PropTypes.bool,
  addStyle: PropTypes.string,
  onMouse: PropTypes.func
}

export default ListView
