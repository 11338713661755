/* eslint-env browser */
import React, { useState, useCallback } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { PostApi, GetApi, exportErrorLog, forceLogout, GetToken } from './ApiAxios'
import useReactRouter from 'use-react-router'
import logo from '../../image/svg/whiteLogo.svg'
import '../../css/Login.scss'
import backImage from '../../image/login_background_image.png'
import logoRole from '../../image/svg/logoRole.svg'
import { useAlert } from 'react-alert'
import { useForm } from 'react-hook-form'
import { InputGroup } from '../Master/Common/CommonInputGroup'
import NormalButton from './Atoms/NormalButton'
import * as regex from './Regex'
import { purgeStoredState } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { clearAuthCookie, setAccessTokenCookie, setRefreshTokenCookie } from './utils'
import googleSigninButton from '../../image/btn_google_signin.png'

const LoginWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-flow: column;
  /* background-image: url('${backImage}'); */
  background-repeat: no-repeat;
  background-position: center;
  background-color: #0f0f0f;
`
const FlexStyle = styled.div`
  display:flex;
  width: 58vw;
  height: 73vh;
  background-color:#000;
  background-image: url('${logoRole}');
  background-repeat: no-repeat;
  background-size: 20%;
  background-position: 5% top;
  color:#FFF;
`
const LoginImageArea = styled.div`
  width:12vw;
  img {
    margin-top: 8vh;
    margin-bottom: 5vh;
    max-width: 100%;
    height: auto;
    width: auto;
  }
`
const LoginArea = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const InputArea = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  
  >label {
    font-size: .9em;
  }
`
// const Remember = styled.div`
//   position: relative;
//   margin: 1vh 0;
//   font-size: .6em;
//   width: 272px;
// `
const LoginButtonArea = styled.div`
  box-sizing:border-box;
`
const CopyRightArea = styled.div`
    margin-top: auto;
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
`
const CopyrightLabel = styled.label`
  color: #cccccc;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.8px;
`

async function checkAuth (tokenInCookie) {
  const apiUrl = '/api/auth'
  const data = { accessToken: tokenInCookie }

  // if (!tokenInCookie) {
  //   clearAuthCookie()
  //   return false
  // }

  const result = await GetApi(apiUrl, data)
  if (result.errorDetail) {
    exportErrorLog(result)
    const errorStatus = result.errorDetail.response && result.errorDetail.response.status
    if (errorStatus === 401) {
      forceLogout()
    } else {
      alert('認証に失敗しました。')
    }
    clearAuthCookie()
    window.location.href = '/login'
    return false
  }
  return true
}

// 認証判定
export const Auth = (props) => {
  const { location } = useReactRouter()
  const [urlPath, setUrlPath] = useState('/')
  const accessToken = GetToken()
  if (urlPath !== location.pathname) {
    setUrlPath(location.pathname)
    const isAuth = checkAuth(accessToken)
    return (
    // eslint-disable-next-line react/prop-types
      isAuth ? props.children : <Redirect to='/login' />
    )
  } else {
    return props.children
  }
}

// ログイン画面
function Login (props) {
  const alert = useAlert()
  const accessToken = GetToken()
  const isAuth = accessToken ? checkAuth(accessToken) : false

  // react-hook-form 関連
  const { register, handleSubmit, errors } = useForm({ validateCriteriaMode: 'all', reValidateMode: 'onChange' })

  const onSubmit = useCallback(async (data, event) => {
    event.preventDefault()
    const apiUrl = '/api/login'
    const result = await PostApi(apiUrl, data)
    if (result.errorDetail) {
      exportErrorLog(result)
      clearAuthCookie()
      alert.error('ログインに失敗しました')
    } else {
      setAccessTokenCookie(result.data.accessToken)
      setRefreshTokenCookie(result.data.refreshToken)
      // getLoginUserInfo()
      const persistConfig = {
        key: 'root',
        version: 1,
        storage
      }
      purgeStoredState(persistConfig)
      // eslint-disable-next-line react/prop-types
      props.history.push('/')
    }
  }, [props, alert])
  // ログイン済の場合はログイン画面をスキップ
  return isAuth
    ? (<Redirect to='/' />)
    : (
      <LoginWrap id='LoginWrap'>
        <form id='loginForm' onSubmit={handleSubmit(onSubmit)}>
          <FlexStyle>
            <LoginArea>
              <LoginImageArea><img src={logo} alt='logo' /></LoginImageArea>
              <InputArea>
                <label>ユーザーID</label>
                <InputGroup
                  autoFocus
                  width='272px'
                  margin='0'
                  type='text'
                  name='loginId'
                  placeholder='ユーザーID'
                  register={register}
                  rule={{
                    required: regex.required,
                    maxLength: regex.maxLength(10)
                  }}
                  errors={errors}
                />
              </InputArea>
              <InputArea>
                <label>パスワード</label>
                <InputGroup
                  autoFocus
                  width='272px'
                  margin='0'
                  type='password'
                  name='password'
                  placeholder='パスワード'
                  register={register}
                  rule={{
                    required: regex.required,
                    maxLength: regex.maxLength(255)
                  }}
                  errors={errors}
                />
              </InputArea>
              <LoginButtonArea>
                <NormalButton type='submit' width='272px' name='ログイン' />
              </LoginButtonArea>
              <a href='/auth/google'>
                <img src={googleSigninButton} alt='google signin' />
              </a>
              <CopyRightArea>
                <CopyrightLabel>&copy; {(new Date().getFullYear())} PAL Co., Ltd.</CopyrightLabel>
              </CopyRightArea>
            </LoginArea>
          </FlexStyle>
        </form>
      </LoginWrap>
    )
}

export default withRouter(Login)
