/* eslint-env browser */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'
import PropTypes from 'prop-types'
import { IconContext } from 'react-icons'
import {
  FaMapMarkerAlt, FaTemperatureLow, FaStackOverflow
} from 'react-icons/fa'
import { useAlert } from 'react-alert'
import Modal from 'react-modal'
import { Tooltip } from '@material-ui/core'
import GoogleMapReact from 'google-map-react'

import CanvasRow from './CanvasRow'
import { LaborStates } from './LaborSlice'
import FUNC from '../Common/FunctionEnum'
import { grantDecision } from '../Common/utils'
import { DeleteApi, PostApi, PutApi } from '../Common/ApiAxios'
import NormalButton from '../Common/Atoms/NormalButton'
import Input from '../Common/Atoms/Input'
import Select from '../Common/Atoms/Select'
import '../../css/Marker.css'

const Label = styled.label`
  width: 70px;
  line-height: 36px;
  font-size: 0.8em;
`

const FlexDiv = styled.div`
  display: flex;
`

const StaffName = styled.div`
  height: 28px;
  line-height: 28px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
`

const StaffId = styled.div`
  height: 25px;
  line-height: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
`

const StaffInfo = styled.div`
  width:150px;
  text-indent:3px;
  background: ${props => props.retireFlg || !props.enterFlg ? '#ddd' : '#fff'} ;
  .enterBefore{
    margin-left:5px;
    color: blue;
    font-size: 10px;
  }
  .retireAfter{
    margin-left:5px;
    color: red;
    font-size: 10px;
  }
  .temperature{
    margin-left:5px;
    color: black;
    font-size: 10px;
    line-height: 28px;
  }
  .temperature-icon{
    margin-left:5px;
    color: black;
    font-size: 13px;
    line-height: 28px;
    cursor: pointer;
  }
`
const normalTempStyle = {
  color: 'black'
}

const highTempStyle = {
  color: 'red',
  fontWeight: 'bold'
}

const Hours = styled.label`
  line-height:50px;
  width:50px;
  text-align:right;
  padding-right:3px;
  font-size: 12px;
  background: #eee;
`

const StaffRow = styled.li`
  min-height:50px;
  max-height:50px;
  flex-shrink: 0;
  display: flex;
  margin-bottom: 3px;
  position:sticky;
  left: 0px;
  z-index:1;
`
const LaborListWrap = styled.div`
`
const LaborList = styled.ul`
  box-sizing: content-box;
`

const ApprovedSpan = styled.span`
  color: red;
  padding-left: 8px;
`
const TextArea = styled.textarea`
  resize: none;
  font-size: 1.2em;
  ::placeholder {
    color: lightgray;
    font-size: 0.9em;
    font: "Noto Sans";
  }
`

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  // margin-bottom: 10px;
  // margin-left: 20px;
  color: #fff;
  position: absolute;
  left: 0;
  top: calc(100% - 70px);
  width: 100%;
`

const ModalWrap = styled.div`
  background-color: rgb(51, 51, 51, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: ${props => props.isModal ? 'flex' : 'none'};
  justify-content:center;
  align-items:center;
`

const ModalMain = styled.div`
  width: 350px;
  height: 200px;
  background: #000;
  border: 2px solid #eee;
`

const ActionWrap = styled.div`
  padding:3px;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`

const ModalStyles = {
  overlay: {
    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, .4)'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    border: 'none',
    width: '75vw',
    height: '75vh',
    zIndex: 101
  }
}

const initialPos = {
  center: {
    lat: 34.7088812,
    lng: 135.4969132
  },
  zoom: 15
}
const Marker = ({ text, color }) => {
  return (
    <div>
      <div
        className='pin bounce'
        style={{ backgroundColor: color, cursor: 'pointer' }}
        title={text}
      />
      <div className='pulse' />
    </div>
  )
}
const getPositionInfo = (labors, staffId) => {
  const target = labors
    .filter(l => l.staffId === staffId && l.latitude !== 0 && l.longitude !== 0)
    .map(ret => ({
      lat: ret.latitude,
      lon: ret.longitude,
      ope: ret.operationName,
      startDt: ret.startDt,
      endDt: ret.endDt
    }))

  return target
}

const getInitPos = (pos) => {
  const lat = pos.reduce((acc, cur) => {
    return acc + cur.lat
  }, 0) / pos.length
  const lng = pos.reduce((acc, cur) => {
    return acc + cur.lon
  }, 0) / pos.length
  return {
    lat: lat,
    lng: lng
  }
}

const isHighTemp = (temp) => {
  return temp && Number(temp) >= 37.5
}

const buildOvertimeInfo = (f, c, h, e) => {
  const foreseeType = f === 1 ? '予測型' : '突発型'
  const causeType = c === 1 ? '顧客起因' : c === 2 ? 'スタッフ起因' : '社員起因'
  return (
    <span style={{ fontSize: '1.1em' }}>
      <p style={{ margin: 3 }}>予測: {foreseeType}</p>
      <p style={{ margin: 3 }}>起因: {causeType}</p>
      <p style={{ margin: 3 }}>予定時間: {h}h</p>
      <p style={{ margin: 3 }}>理由: {e}</p>
    </span>
  )
}
const NORMAL_TEMP = 36.5
// キャンバスリスト
function CanvasList ({
  isPlanned, register, workPlace, grants, nowTimeIndex, wrapRef, approvalData, staffDivisionFilter, bodyTemp, overtimeInfo, setOvertimeInfo
}) {
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const labor = LaborStates()
  const dblabors = labor.labors
  const hours = labor.laborHours
  const warehouseId = commonSearch.warehouseId
  const workDt = commonSearch.workDate
  // Map
  const [isModalShow, setIsModalShow] = useState(false)
  const [posInfo, setPosInfo] = React.useState([])
  const [initialPosition, setInitialPosition] = useState({})
  // Temperature
  const [isTempModalShow, setIsTempModalShow] = useState(false)
  const [myTemperature, setMyTemperature] = useState(NORMAL_TEMP)
  const [registeredTemp, setRegisteredTemp] = useState(null)
  const [registeredTempId, setRegisteredTempId] = useState(null)
  // Overtime
  const [isOvertimeModalShow, setIsOvertimeModalShow] = useState(false)
  const [foreseeType, setForeseeType] = useState(1)
  const [causeType, setCauseType] = useState(1)
  const [estimateHours, setEstimateHours] = useState(1)
  const [explanation, setExplanation] = useState('')
  const [overtimeId, setOvertimeId] = useState(0)
  const [updateOvertimeDt, setUpdateOvertimeDt] = useState(null)
  const [overtimeRegistButtonStatus, setOvertimeRegistButtonStatus] = useState(false)
  const alert = useAlert()
  const showMap = (pos) => () => {
    setIsModalShow(true)
    setPosInfo(pos)
    setInitialPosition(getInitPos(pos))
  }

  const showTempModal = () => {
    setIsTempModalShow(true)
  }

  const closeTempModal = () => {
    setIsTempModalShow(false)
  }

  const showOvertimeModal = () => {
    setIsOvertimeModalShow(true)
  }
  const showOvertimeModalForUpdate = (oid, f, c, h, e, updDt) => () => {
    console.log(oid, f, c, h, e)
    setOvertimeId(oid)
    setForeseeType(f)
    setCauseType(c)
    setEstimateHours(h)
    setExplanation(e)
    setUpdateOvertimeDt(updDt)
    setIsOvertimeModalShow(true)
  }

  const closeOvertimeModal = () => {
    setIsOvertimeModalShow(false)
  }
  const handleModalHide = () => {
    setIsModalShow(false)
  }

  // 体温新規登録
  const insertTemp = async (e) => {
    e.preventDefault()
    const temperatureForRegister = e.target.temperature.value
    if (temperatureForRegister === '') {
      alert.info('体温を入力してください')
      return null
    }
    const ret = await registTemperature(temperatureForRegister)

    if (ret.errorDetail) {
      alert.error('登録に失敗しました')
      setIsTempModalShow(false)
      return null
    }

    setIsTempModalShow(false)
    setRegisteredTemp(temperatureForRegister)
    setRegisteredTempId(ret.data.id)
    alert.success('登録しました')
  }

  const registTemperature = async (temperature) => {
    const url = '/api/bodyTemperature'
    const data = {
      staffId: loginUserInfo.staffId,
      filingDate: workDt,
      bodyTemperature: temperature
    }
    console.log(data)
    const ret = await PostApi(url, data, true)

    return ret
  }

  // 体温更新
  const showTempModalForUpdate = (id, temp) => () => {
    setRegisteredTempId(id)
    setMyTemperature(temp)
    setIsTempModalShow(true)
  }

  const updateTemp = async (e) => {
    e.preventDefault()
    const temperatureForUpdate = e.target.temperature.value
    if (temperatureForUpdate === '') {
      alert.info('体温を入力してください')
      return null
    }

    const ret = await updateTemperature(temperatureForUpdate)

    if (ret.errorDetail) {
      alert.error('更新に失敗しました')
      setIsTempModalShow(false)
      return null
    }

    setIsTempModalShow(false)
    setRegisteredTemp(temperatureForUpdate)
    alert.success('更新しました')
  }

  const updateTemperature = async (temperature) => {
    const url = `/api/bodyTemperature/${registeredTempId}`
    const data = {
      staffId: loginUserInfo.staffId,
      filingDate: workDt,
      bodyTemperature: temperature
    }
    console.log(data)
    const ret = await PutApi(url, data, true)

    return ret
  }

  const registeOvertime = async (foreseeType, causeType, hours, explanation) => {
    const url = '/api/staffOvertimeReason'
    const data = {
      staffId: loginUserInfo.staffId,
      workDate: workDt,
      foreseeType: foreseeType,
      causeType: causeType,
      hours: hours,
      explanation: explanation
    }
    console.log(data)
    const ret = await PostApi(url, data, true)

    return ret
  }

  const updateOvertime = async (overtimeId, foreseeType, causeType, hours, explanation) => {
    console.log('updateOvertime')
    const url = `/api/staffOvertimeReason/${overtimeId}`
    const data = {
      staffId: loginUserInfo.staffId,
      workDate: workDt,
      foreseeType: foreseeType,
      causeType: causeType,
      hours: hours,
      explanation: explanation
    }
    console.log(data)
    const ret = await PutApi(url, data, true)

    return ret
  }

  const handleRegistOvertime = async (e) => {
    e.preventDefault()
    setOvertimeRegistButtonStatus(true)
    // console.log('--- registOvertime ---')
    const prediction = e.target.prediction.value
    const reason = e.target.reason.value
    const estimateHours = e.target.estimateHours.value
    const explanation = e.target.explanation.value
    console.log(prediction, reason, estimateHours, explanation)

    const ret = overtimeId > 0
      ? await updateOvertime(overtimeId, prediction, reason, estimateHours, explanation)
      : await registeOvertime(prediction, reason, estimateHours, explanation)

    // const ret = await registeOvertime(prediction, reason, estimateHours, explanation)

    if (ret.errorDetail) {
      alert.error('更新に失敗しました')
      setIsOvertimeModalShow(false)
      setOvertimeRegistButtonStatus(false)
      return null
    }

    setIsOvertimeModalShow(false)
    // setRegisteredTemp(temperatureForRegister)
    console.log(ret)
    setUpdateOvertimeDt(Date.now())
    setOvertimeId(ret.data.id)
    setExplanation(ret.data.explanation)
    setCauseType(ret.data.causeType)
    setForeseeType(ret.data.foreseeType)
    setEstimateHours(ret.data.hours)
    console.log(ret.data)
    setOvertimeRegistButtonStatus(false)
    alert.success('登録しました')
  }

  const existLabors = () => {
    if (!dblabors) return []

    let _staffId = null
    const loginUserLabor = []
    let userLabor = []
    const user2 = []
    for (const labor of dblabors) {
      if (_staffId == null || _staffId !== labor.staffId) {
        const hasPositionInfo = getPositionInfo(dblabors, labor.staffId)
        userLabor = { ...labor, pos: hasPositionInfo }
        if (loginUserInfo.staffId === labor.staffId) {
          loginUserLabor.push(userLabor)
        } else {
          user2.push(userLabor)
        }
      }

      _staffId = labor.staffId
    }
    // console.log(user2)
    return loginUserLabor.concat(user2)
    // return user.concat(results)
  }

  const handleForeseeTypeChange = (e) => {
    console.log('handleForeseeTypeChange')
    console.log(e.target.value)
    setForeseeType(e.target.value)
  }

  const handleCauseTypeChange = (e) => {
    console.log('handleForeseeTypeChange')
    console.log(e.target.value)
    setCauseType(e.target.value)
  }

  const ModalTemperatureInput = ({ tempId }) => {
    return (
      <Wrap>
        <ModalWrap isModal={isTempModalShow}>
          <form onSubmit={tempId ? updateTemp : insertTemp}>
            <ModalMain>
              <ActionWrap>
                <h1>体温登録</h1>

                <label htmlFor='temperature' style={{ display: 'flex' }}>
                  <Input
                    id='temperature'
                    name='temperature'
                    type='number'
                    step='0.1'
                    min={35}
                    max={42}
                    defaultValue={Number(myTemperature).toFixed(1)}
                  />
                  <p style={{ lineHeight: '25px' }}>℃</p>
                </label>
                <div style={{ display: 'flex' }}>
                  <NormalButton
                    name='登録'
                  />
                  <NormalButton
                    onClick={closeTempModal}
                    name='キャンセル'
                  />
                </div>
              </ActionWrap>
            </ModalMain>
          </form>
        </ModalWrap>
      </Wrap>
    )
  }

  /**
   * 残業申請フォーム
   * @returns
   */
  const ModalOvertimeInput = () => {
    const handleDelete = (id) => async (e) => {
      e.preventDefault()
      const url = `/api/staffOvertimeReason/${id}`
      const result = await DeleteApi(url, '')
      if (result.errorDetail) {
        setIsOvertimeModalShow(false)
        alert.error('削除に失敗しました')
        return null
      }
      setIsOvertimeModalShow(false)
      setUpdateOvertimeDt(null)
      setOvertimeId(0)
      setExplanation('')
      setCauseType(1)
      setForeseeType(1)
      setEstimateHours(1)
      alert.success('削除しました')
      const newOvertimeInfo = overtimeInfo.filter(o => o.id !== id)
      setOvertimeInfo(newOvertimeInfo)
    }

    return (
      <Wrap>
        <ModalWrap isModal={isOvertimeModalShow}>
          <form onSubmit={handleRegistOvertime}>
            <ModalMain style={{ height: '400px' }}>
              <ActionWrap>
                <h1>残業申請登録</h1>
                <FlexDiv>
                  <Label>予見種別</Label>
                  <Select
                    width='200px'
                    id='prediction'
                    name='prediction'
                    selectValue={foreseeType.toString()}
                    onChange={handleForeseeTypeChange}
                  >
                    <option value='1'>予見型</option>
                    <option value='2'>突発型</option>
                  </Select>
                </FlexDiv>
                <FlexDiv>
                  <Label>原因種別</Label>
                  <Select
                    width='200px'
                    id='reason'
                    name='reason'
                    selectValue={causeType.toString()}
                    onChange={handleCauseTypeChange}
                  >
                    <option value='1'>顧客起因</option>
                    <option value='2'>スタッフ起因</option>
                    <option value='3'>社員起因</option>
                  </Select>
                </FlexDiv>
                <FlexDiv>
                  <Label>予定時間</Label>
                  <Input
                    required
                    id='estimateHours'
                    name='estimateHours'
                    type='number'
                    step='0.25'
                    min={0.25}
                    max={16}
                    defaultValue={Number(estimateHours)}
                  />
                  <p style={{ lineHeight: '38px' }}>h</p>
                </FlexDiv>
                <TextArea
                  id='explanation' name='explanation'
                  rows={3} cols={30} maxLength={20} placeholder=' 補足説明（20文字まで）'
                  defaultValue={explanation}
                />

                <div style={{ display: 'flex' }}>
                  <NormalButton id='submit' name='登録' disabled={overtimeRegistButtonStatus} />
                  {overtimeId !== 0 && <NormalButton name='削除' onClick={handleDelete(overtimeId)} />}
                  <NormalButton
                    onClick={closeOvertimeModal}
                    name='キャンセル'
                  />
                </div>
              </ActionWrap>
            </ModalMain>
          </form>
        </ModalWrap>
      </Wrap>
    )
  }

  function showMyTempetarureAndOvertimeApplication (temperature, overtimeInfo) {
    const t = registeredTemp || (temperature && temperature.length > 0 && temperature[0].bodyTemperature)
    const tempId = registeredTempId || (temperature && temperature.length > 0 && temperature[0].id)
    const temp = (temperature && temperature.length > 0) || registeredTemp
      ? (
        <a
          href='#'
          onClick={showTempModalForUpdate(tempId, t)}
        >
          <span
            className='temperature'
            style={
              isHighTemp(registeredTemp || temperature[0].bodyTemperature)
                ? highTempStyle
                : normalTempStyle
            }
          >
            {
              registeredTemp
                ? Number(registeredTemp).toFixed(1)
                : temperature[0].bodyTemperature.toFixed(1)
            }℃
          </span>
        </a>)
      : (
        <Tooltip title='体温登録'>
          <div className='temperature-icon' onClick={showTempModal}>
            <IconContext.Provider value={{ color: 'gray' }}>
              <FaTemperatureLow />
            </IconContext.Provider>
          </div>
        </Tooltip>
      )

    const updDt = overtimeInfo.length > 0 && overtimeInfo[0].createdAt
    const f = updateOvertimeDt
      ? foreseeType
      : (overtimeInfo && overtimeInfo.length > 0 && overtimeInfo[0].foreseeType) || foreseeType

    const c = updateOvertimeDt
      ? causeType
      : (overtimeInfo && overtimeInfo.length > 0 && overtimeInfo[0].causeType) || causeType

    const h = updateOvertimeDt
      ? estimateHours
      : (overtimeInfo && overtimeInfo.length > 0 && overtimeInfo[0].hours) || estimateHours

    const e = updateOvertimeDt
      ? explanation
      : (overtimeInfo && overtimeInfo.length > 0 && overtimeInfo[0].explanation) || explanation

    const oid = (overtimeInfo && overtimeInfo.length > 0 && overtimeInfo[0].id) || overtimeId

    console.log(f, c, h, e, overtimeInfo)
    console.log(`foreseeType: ${foreseeType}, causeType: ${causeType}, explanation: ${explanation}, overtimeId: ${overtimeId}, updateOvertimeDt: ${updateOvertimeDt}`)
    const overtime = overtimeId || (overtimeInfo && overtimeInfo.length > 0)
      ? (
        <Tooltip title={buildOvertimeInfo(f, c, h, e)}>
          <div
            className='temperature-icon'
            onClick={showOvertimeModalForUpdate(oid, f, c, h, e, updDt)}
          >
            <IconContext.Provider value={{ color: 'red' }}>
              <FaStackOverflow />
            </IconContext.Provider>
          </div>
        </Tooltip>
      )
      : (
        <Tooltip title='残業申請'>
          <div className='temperature-icon' onClick={showOvertimeModal}>
            <IconContext.Provider value={{ color: 'gray' }}>
              <FaStackOverflow />
            </IconContext.Provider>
          </div>
        </Tooltip>
      )

    return (
      <>
        {temp}
        {overtime}
      </>

    )
  }

  const showOtherTempetarureAndOvertimeApplication = (temperature, overtimeInfo) => {
    const temp = temperature && temperature.length > 0 && (
      <span
        className='temperature'
        style={
          isHighTemp(temperature[0].bodyTemperature)
            ? highTempStyle
            : normalTempStyle
        }
      >
        {temperature[0].bodyTemperature}℃
      </span>)

    const overtime = overtimeInfo && overtimeInfo.length > 0 && (
      <Tooltip
        title={buildOvertimeInfo(overtimeInfo[0].foreseeType, overtimeInfo[0].causeType, overtimeInfo[0].hours, overtimeInfo[0].explanation)}
      >
        <div className='temperature-icon'>
          <IconContext.Provider value={{ color: '#d70035' }}>
            <FaStackOverflow />
          </IconContext.Provider>
        </div>
      </Tooltip>
    )

    return (
      <>
        {temp}
        {overtime}
      </>
    )
  }

  useEffect(() => {
    setRegisteredTemp(null)
    setRegisteredTempId(null)
    setMyTemperature(NORMAL_TEMP)
    setCauseType(1)
    setForeseeType(1)
    setEstimateHours(1)
    setExplanation('')
    setOvertimeId(0)
    setUpdateOvertimeDt(null)
  }, [workDt])

  if (!dblabors) return null
  if (!hours) return null

  return (
    <LaborListWrap>
      <LaborList id='LaborList'>
        {existLabors().map((labor, index) => {
          const { staffId, staffName, enterFlg, retireFlg, pos } = labor
          const isMyRow = loginUserInfo.staffId === staffId
          const target = hours.filter(item => item.staffId === staffId)
          const sumHour = (target && target.length > 0) ? target[0].hours : 0
          const sumHourStr = sumHour.toFixed(2) + 'H'
          const temperature = bodyTemp?.filter(b => b.staffId === staffId)
          const overtime = overtimeInfo?.filter(o => o.staffId === staffId)
          // console.log(overtime)
          const approved = approvalData?.filter(data => data.staffId === staffId).length > 0
          // const myTemperatureId = temperature && temperature.length > 0 && temperature[0].id
          const canReadPosInfo = grantDecision('read', FUNC.RESULT_LABOR_POS_INFO, loginUserInfo, warehouseId, staffId)
          return (
            <FlexDiv key={index}>
              <StaffRow>
                <StaffInfo
                  enterFlg={enterFlg}
                  retireFlg={retireFlg}
                  style={isMyRow
                    ? { backgroundColor: '#f5f53d' }
                    : (!isPlanned && approved)
                      ? { backgroundColor: '#FFF9C4', color: '#333' } : {}}
                >
                  <StaffId>{staffId}
                    {!enterFlg && <span className='enterBefore'>入社前</span>}
                    {retireFlg && <span className='retireAfter'>退職済</span>}
                    {!isPlanned && approved && <ApprovedSpan>承認済み</ApprovedSpan>}
                  </StaffId>
                  <div style={{ display: 'flex' }}>
                    <StaffName>{staffName}</StaffName>
                    {
                      (canReadPosInfo && !isPlanned && pos.length > 0) && (
                        <div onClick={showMap(pos)}>
                          <IconContext.Provider value={{ color: 'gray' }}>
                            <FaMapMarkerAlt />
                          </IconContext.Provider>
                        </div>
                      )
                    }
                    {isMyRow
                      ? showMyTempetarureAndOvertimeApplication(temperature, overtime)
                      : showOtherTempetarureAndOvertimeApplication(temperature, overtime)}
                  </div>

                </StaffInfo>
                <Hours>{sumHourStr}</Hours>
              </StaffRow>
              <CanvasRow
                staffId={staffId}
                isPlanned={isPlanned}
                register={register}
                workPlace={workPlace}
                grants={grants}
                nowTimeIndex={nowTimeIndex}
                wrapRef={wrapRef}
                approved={approved}
                staffDivisionFilter={staffDivisionFilter}
              />
            </FlexDiv>
          )
        })}
      </LaborList>

      <Modal
        isOpen={isModalShow}
        onRequestClose={handleModalHide}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <div style={{ height: '75vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyAtA1jIcGNHbFwHitDANOGzYlM62KoF-k8' }}
            defaultCenter={initialPosition}
            defaultZoom={initialPos.zoom}
          >
            {
              posInfo.map(p => {
                return (
                  <Marker
                    key={p.startDt}
                    lat={p.lat}
                    lng={p.lon}
                    text={p.ope + ':' + p.startDt + '～' + p.endDt}
                    color='red'
                  />
                )
              }
              )
            }
          </GoogleMapReact>
        </div>
      </Modal>
      {/* 体温登録フォーム */}
      <ModalTemperatureInput
        tempId={registeredTempId}
      />
      {/* 残業登録フォーム */}
      <ModalOvertimeInput />
    </LaborListWrap>
  )
}

CanvasList.propTypes = {
  isPlanned: PropTypes.bool,
  register: PropTypes.number,
  workPlace: PropTypes.number,
  retireFlg: PropTypes.bool,
  enterFlg: PropTypes.bool,
  grants: PropTypes.object,
  nowTimeIndex: PropTypes.number,
  wrapRef: PropTypes.any,
  approvalData: PropTypes.object,
  staffDivisionFilter: PropTypes.number
}

export default CanvasList
