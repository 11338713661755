/* eslint-env browser */
import React, { useEffect, lazy, Suspense, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { SetCompanyInfo } from './Common/Slice/CompanyInfoSlice'
import styled from 'styled-components'
import Theme from './Common/Theme'
import '../css/App.scss'
import Header from './Header'
import Main from './Main'
import ZoneMaster from './Master/Zone/ZoneMaster'
import Mapping from './Mapping/Mapping'
import EditZone from './Mapping/EditZone'
import Labor from './Labor/Labor'
import PersonalLabor from './Labor/PersonalLabor'
import MonthlyLabor from './Labor/MonthlyLabor/MonthlyLabor'
import MonthlyLaborSummary from './Labor/MonthlyLabor/MonthlyLaborSummary'
import IndividualMonthly from './Labor/IndividualMonthly/IndividualMonthly'
import { LaborApproval } from './Labor/Approval'
import Master from './Master/Master'
import Operation from './Master/Operation/Operation'
import DndOperation from './Master/Operation/DndOperation'
import CategoryMaster from './Master/Category/Category'
import FloorMaster from './Master/Floor/Floor'
import Staff from './Master/Staff/Staff'
import Grant from './Master/Grant/Grant'
import Role from './Master/Role/Role'
import MasterImport from './Master/MasterImport/MasterImport'
import CredentialsApikey from './Master/CredentialsApikey/CredentialsApikey'
import CsvExport from './CsvExport'
import NotFound from './NotFound'
import Login, { Auth } from './Common/Login'
import ChangePassword from './Common/ChangePassword'
import { GetApi, exportErrorLog, forceLogout } from './Common/ApiAxios'
import { setLoginUserInfo } from './Common/Slice/LoginSlice'
import { getLoginUserInfo } from './Common/StaffInfo'
import LoadSpinner from './Common/LoadSpinner'
import PageTop from './Common/Atoms/PageTop'
import AppDownloadQr from './Download/AppDownloadQr'
import { initializeGA } from './Common/AnalyticsUtils'
import { restfulApiConfig } from './Common/Config'
const WhiteBoardMenu = lazy(() => import('./WhiteBoard/WhiteBoardMenu'))
const WhiteBoard = lazy(() => import('./WhiteBoard/WhiteBoard'))
const Placement = lazy(() => import('./WhiteBoard/Placement'))
const Positioning = lazy(() => import('./WhiteBoard/Positioning'))
const PositioningTop = lazy(() => import('./WhiteBoard/Positioning/PositioningTop'))
const PositioningBoard = lazy(() => import('./WhiteBoard/Positioning/PositioningBoard'))
const PositioningWarehouse = lazy(() => import('./WhiteBoard/Positioning/Setting/Warehouse/PositioningWarehouse'))
const PositioningFloor = lazy(() => import('./WhiteBoard/Positioning/Setting/Floor/PositioningFloor'))
const PositioningArea = lazy(() => import('./WhiteBoard/Positioning/Setting/Area/PositioningArea'))
const PositioningRouter = lazy(() => import('./WhiteBoard/Positioning/Setting/Router/PositioningRouter'))
const PositioningWeatherSensor = lazy(() => import('./WhiteBoard/Positioning/Setting/WeatherSensor/PositioningWeatherSensor'))
const PositioningTag = lazy(() => import('./WhiteBoard/Positioning/Setting/Tag/PositioningTag'))
const PositioningCamera = lazy(() => import('./WhiteBoard/Positioning/Setting/Camera/PositioningCamera'))
const PositioningWatch = lazy(() => import('./WhiteBoard/Positioning/Setting/Watch/PositioningWatch'))

const Progress = lazy(() => import('./WhiteBoard/Progress'))
const Material = lazy(() => import('./WhiteBoard/Material'))
const QuantityManagement = lazy(() => import('./QuantityManagement/QuantityManagement'))
const QuantityData = lazy(() => import('./QuantityManagement/QuantityData'))
const ProductivityData = lazy(() => import('./QuantityManagement/ProductivityData'))
const OperationData = lazy(() => import('./QuantityManagement/OperationData'))
const Quantity = lazy(() => import('./Master/Quantity/Quantity'))
const Productivity = lazy(() => import('./Master/Productivity/Productivity'))
const TimeFrame = lazy(() => import('./Master/TimeFrame/TimeFrame'))
const SkillMaster = lazy(() => import('./Master/Skill/Skill'))
const CompanyMaster = lazy(() => import('./Master/Company/Company'))
const UnitsMaster = lazy(() => import('./Master/Units/Units'))
const WarehouseMaster = lazy(() => import('./Master/Warehouse/Warehouse'))

const Container = styled.div`
  .App {
    flex-direction: column;
    background-repeat: no-repeat;
    background-color: ${props => props.theme.bgBase};
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
  }
`

function App () {
  const { loginUserInfo, companyInfo } = useSelector(state => state)
  const userInfo = { loginUserInfo: loginUserInfo, setLoginUserInfo: setLoginUserInfo }
  const dispatch = useDispatch()

  // ログインスタッフ情報・会社情報取得
  useEffect(() => {
    // 会社情報
    async function getCompanyData () {
      const apiUrl = '/api/companyInfo'
      const companyId = loginUserInfo.companyId
      const data = { companyId }
      const result = await GetApi(apiUrl, data)
      if (result.errorDetail) {
        exportErrorLog(result)
        const errorStatus = result.errorDetail.response && result.errorDetail.response.status
        if (errorStatus === 401) {
          forceLogout()
        } else {
          alert('情報取得に失敗しました。')
        }
        window.location.href = '/login'
      } else {
        dispatch(SetCompanyInfo(result.data))
      }
    }

    async function getLoginUserInfo_ () {
      getLoginUserInfo(dispatch)
    }
    if (loginUserInfo.staffId === undefined) {
      getLoginUserInfo_()
    }
    if (loginUserInfo.companyId && !companyInfo.companyId) {
      getCompanyData()
    }
  }, [loginUserInfo, dispatch])

  const router = useRef(null)

  initializeGA(restfulApiConfig.measurementId)
  useEffect(() => {
    if(restfulApiConfig.measurementId){
      router.current.history.listen((location) => {
        window.gtag('config', restfulApiConfig.measurementId, {
          page_path: `${location.pathname}${location.search}`
        })
      })
    }
  })

  return (
    <Theme themeName='default'>
      <Container>
        <Router ref={router}>
          <Suspense fallback={<LoadSpinner />}>
            <LoadSpinner />
            <Switch>
              <Route path='/login' render={() => (<Login userInfo={userInfo} />)} exact />
              <Auth>
                <div className='App' id='App'>
                  <PageTop />
                  <Header />
                  <div className='main' style={{height:'100%', backgroundColor:'#fff'}}>
                    <Switch>
                      <Route path='/' component={Main} exact />
                      <Route path='/whiteboard' component={WhiteBoardMenu} exact />
                      <Route path='/whiteboard/whiteboard' component={WhiteBoard} />
                      <Route path='/whiteboard/mapping' component={Mapping} />
                      <Route path='/whiteboard/placement' component={Placement} />
                      <Route path='/whiteboard/positioning/top' component={PositioningTop} />
                      <Route path='/whiteboard/positioning/board' component={PositioningBoard} />
                      <Route path='/whiteboard/positioning/setting/warehouse' component={PositioningWarehouse} />
                      <Route path='/whiteboard/positioning/setting/floor' component={PositioningFloor} />
                      <Route path='/whiteboard/positioning/setting/area' component={PositioningArea} />
                      <Route path='/whiteboard/positioning/setting/router' component={PositioningRouter} />
                      <Route path='/whiteboard/positioning/setting/weatherSensor' component={PositioningWeatherSensor} />
                      <Route path='/whiteboard/positioning/setting/tag' component={PositioningTag} />
                      <Route path='/whiteboard/positioning/setting/camera' component={PositioningCamera} />
                      <Route path='/whiteboard/positioning/setting/watch' component={PositioningWatch} />

                      {/* いまは未使用 start */}
                      <Route path='/whiteboard/progress' component={Progress} />
                      <Route path='/whiteboard/editzone' component={EditZone} />
                      <Route path='/material' component={Material} />
                      {/* いまは未使用 end */}

                      {/* レイバー */}
                      <Route path='/labor/planned' render={() => (<Labor isPlanned />)} />
                      <Route path='/labor/result' render={() => (<Labor isPlanned={false} />)} />
                      <Route path='/labor/personal' component={PersonalLabor} />
                      <Route path='/labor/monthly' component={MonthlyLabor} />
                      <Route path='/labor/list' component={MonthlyLaborSummary} />
                      <Route path='/labor/individual' component={IndividualMonthly} />
                      <Route path='/labor/approval' component={LaborApproval} />

                      {/* 物量管理 */}
                      <Route path='/quantity' component={QuantityManagement} exact />
                      <Route path='/quantity/productivityData'>
                        <ProductivityData
                          loginUserInfo={loginUserInfo}
                        />
                      </Route>
                      <Route path='/quantity/quantityData'>
                        <QuantityData
                          loginUserInfo={loginUserInfo}
                          OperationData
                        />
                      </Route>
                      <Route path='/quantity/operationData'>
                        <OperationData
                          loginUserInfo={loginUserInfo}
                        />
                      </Route>
                      {/* スマホアプリダウンロードページ */}
                      <Route path='/appDownloadQr' component={AppDownloadQr} />

                      {/* マスター */}
                      <Route path='/master' component={Master} exact />
                      <Route path='/master/ope1' component={Operation} />
                      <Route path='/master/operation' component={DndOperation} />
                      <Route path='/master/quantity' component={Quantity} />
                      <Route path='/master/productivity' component={Productivity} />
                      <Route path='/master/company' component={CompanyMaster} />
                      <Route path='/master/units' component={UnitsMaster} />
                      <Route path='/master/category' component={CategoryMaster} />
                      <Route path='/master/skill' component={SkillMaster} />
                      <Route path='/master/warehouse' component={WarehouseMaster} />
                      <Route path='/master/floor' component={FloorMaster} />
                      <Route path='/master/timeFrame' component={TimeFrame} />
                      <Route path='/master/staff' component={Staff} />
                      <Route path='/master/grant' component={Grant} />
                      <Route path='/master/role' component={Role} />
                      <Route path='/master/zone' component={ZoneMaster} />
                      <Route path='/master/masterImport' component={MasterImport} />
                      <Route path='/master/credentialsApikey' component={CredentialsApikey} />

                      {/* CSVダウンロード */}
                      <Route path='/data-management/' component={CsvExport} />

                      {/* 共通 */}
                      <Route path='/changePassword' component={ChangePassword} exact />

                      {/* 404 not found */}
                      <Route component={NotFound} />
                    </Switch>
                  </div>
                </div>
              </Auth>
            </Switch>
          </Suspense>
        </Router>
      </Container>
    </Theme>
  )
};
export default App
