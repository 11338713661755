import React from 'react'
import { ThemeProvider } from 'styled-components'

const defaultTheme = {
  bgBase: '#111',
  text: {
    color: {
      primary: '#fff',
      secondary: '#333'
    }
  },
  gray: {
    100: '#eeeeee',
    300: '#cccccc',
    500: '#777777',
    700: '#555555'
  },
  button: {
    default: '#0036ff',
    hover: '#0026b3'
  },
  selectButton: {
    default: '#cccccc',
    selected: '#649CDF'
  },
  header: {
    background: '#00000080'
  }
}

const whiteTheme = {
  bgBase: '#fafafa',
  text: {
    color: {
      primary: '#37474f',
      secondary: '#fff'
    }
  },
  gray: {
    100: '#eeeeee',
    300: '#cccccc',
    500: '#777777',
    700: '#555555'
  },
  button: {
    default: '#0036ff',
    hover: '#0026b3'
  },
  selectButton: {
    default: '#cccccc',
    selected: '#649CDF'
  },
  header: {
    background: '#ffffff80'
  }
}

var theme = {}
theme.default = defaultTheme
theme.white = whiteTheme

export const Theme = ({ themeName = 'default', children }) => <ThemeProvider theme={theme[themeName]}>{children}</ThemeProvider>
export default Theme
