/* eslint-env browser */
import React, { useRef, useState, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PutApi, DeleteApi, PostApi, exportErrorLog, forceLogout } from '../../../../Common/ApiAxios'
import { DotSpinner, ShowSpinner, HideSpinner } from './Spinner'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import LaborDetailPop from './LaborDetailPop'
import { getIndividualMonthlyLabors } from '../../IndividualMonthlySlice'
import Columns from './Columns'
import { CreateName, Enum, mouseXToTaskIndex } from './Commons'
import { newDraw, moveDraw, resizeDraw } from './Drawing'
import ContextMenu, { VariableDeleteButton, SelectWorkPlace } from './ContextMenu'

const WrapDiv = styled.div`
  position: relative;
`
const ContextMenuWrap = styled.span`
  display: none;
`
const RowDiv = styled.div`
  padding-left: 15px;
  display: flex;
  position: relative;
  background: #fff;
  height: 50px;
  &:hover .detailLabor{
    display: block;
  }
`

const Item = styled.div`
  width: ${props => props.width || 15}px;
  height: 40px;
  position: absolute;
  top: 5px;
  display: ${props => props.display || 'block'};
  left: ${props => props.left || 0}px;
  background: ${props => props.background || '#333'};
  border-radius: 5px;
  border: 1px dashed #fff;
  color: white;
  font-size: 11px;
  font-family: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif';
  line-height: 38px;
  text-align: center;
`

// レコードの1行
const RowRight = (props) => {
  const {
    grants, wrapRef, staff, dateItems, laborToggle, month, allUpdateOpen, approved = false,
    plannedStartIndex, plannedEndIndex
  } = props

  const labors = dateItems.labors
  const dispatch = useDispatch()
  const { laborSlice, commonSearch, loginUserInfo } = useSelector(state => state)
  const operations = laborSlice.operations // 工程一覧
  const selectOperation = laborSlice.selectOperation // 選択した工程
  const selectOpeWorkPlace = laborSlice.selectOpeWorkPlace // 選択した勤怠区分

  const rowRef = useRef() // 行
  const editItemRef = useRef([]) // 操作中Ref
  const newItemRef = useRef() // 新規作成Ref
  const spinnerRef = useRef() // スピナーRef
  const contextMenuRef = useRef() // 右クリックメニューRef

  const [editingItem, setEditingItem] = useState()// 操作中Item
  const [beforeItem, setBeforeItem] = useState()// 移動・リサイズ前のItem
  const [drawing, setDrawing] = useState('') // 描写のモード new：新規、move：既存移動、resize：既存リサイズ
  const [mouseStatus, setMouseStatus] = useState('up')

  // 移動用
  const [startDiffIndex, setStartDiffIndex] = useState(0) // 移動開始時のマウスの位置と対象レイバーのスタート位置との差
  const [endDiffIndex, setEndDiffIndex] = useState(0) // 移動開始時のマウスの位置と対象レイバーのエンド位置との差

  // 新規用
  const [startIndex, setStartIndex] = useState(0) // 新規登録時のスタート位置

  // 右クリック用
  const [contextMenuPosition, setContextMenuPosition] = useState(0) // 右クリックメニュー表示位置
  const [contextMenuList, setContextMenuList] = useState() // 右クリックmenuList

  // レイバー新規登録
  const PostLabor = async () => {
    localStorage.setItem('processingFlg', true)
    const apiUrl = '/api/labor'
    const data = {
      staffId: staff.staffId,
      floorId: selectOperation.floorId,
      workDate: editingItem.workDate,
      labor: {
        backColor: selectOperation.backColor,
        branchNo: editingItem.branchNo,
        endIndex: editingItem.endIndex,
        endPixel: editingItem.endPixel,
        operationId: selectOperation.id,
        operationName: selectOperation.operationName,
        staffId: staff.staffId,
        startIndex: editingItem.startIndex,
        startPixel: editingItem.startPixel,
        workPlace: selectOpeWorkPlace
      },
      isPlanned: laborToggle === 'planned',
      workPlace: selectOpeWorkPlace
    }
    const result = await PostApi(apiUrl, data, true)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
        forceLogout()
      } else {
        alert('登録に失敗しました。')
      }
      HideSpinner(spinnerRef.current)
      localStorage.setItem('processingFlg', false)
    } else {
      dispatch(getIndividualMonthlyLabors(staff, month, laborToggle))
      HideSpinner(spinnerRef.current)
      localStorage.setItem('processingFlg', false)
    }
  }

  // API PUT レイバー更新
  const PutLabor = async () => {
    localStorage.setItem('processingFlg', true)
    const apiUrl = '/api/labor'
    const data = {
      staffId: staff.staffId,
      floorId: editingItem.floorId,
      workDate: editingItem.workDate,
      labor: editingItem,
      isPlanned: laborToggle === 'planned'
    }
    const result = await PutApi(apiUrl, data, true)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
        forceLogout()
      } else {
        alert('更新に失敗しました。')
      }
      localStorage.setItem('processingFlg', false)
    } else {
      dispatch(getIndividualMonthlyLabors(staff, month, laborToggle))
      HideSpinner(spinnerRef.current)
      localStorage.setItem('processingFlg', false)
    }
  }

  // API DELETE レイバー削除
  const deleteLabor = async (targetLabor) => {
    if (targetLabor == null) return null
    const halfX = targetLabor.startPixel + ((targetLabor.endPixel - targetLabor.startPixel) / 2)
    ShowSpinner(spinnerRef.current, halfX)
    localStorage.setItem('processingFlg', true)
    const apiUrl = '/api/labor'
    const data = {
      laborId: targetLabor.laborId,
      isPlanned: laborToggle === 'planned'
    }
    const result = await DeleteApi(apiUrl, data)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
        forceLogout()
      } else {
        alert('削除に失敗しました。')
      }
      HideSpinner(spinnerRef.current)
      localStorage.setItem('processingFlg', false)
    } else {
      dispatch(getIndividualMonthlyLabors(staff, month, laborToggle))
      HideSpinner(spinnerRef.current)
      rowRef.current.style.cursor = selectOperation ? Enum.CURSOR_TEXT : Enum.CURSOR_DEFAULT
      HideSpinner(spinnerRef.current)
      localStorage.setItem('processingFlg', false)
    }
  }

  // API DELETE レイバー全削除
  const deleteAllLabor = async () => {
    const ret = window.confirm('全削除します。よろしいですか？')
    if (!ret) return null
    localStorage.setItem('processingFlg', true)
    const apiUrl = '/api/labor/all'
    const data = {
      staffId: staff.staffId,
      workDate: dateItems.dateValue,
      isPlanned: laborToggle === 'planned'
    }
    const result = await DeleteApi(apiUrl, data)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
        forceLogout()
      } else {
        alert('削除に失敗しました。')
      }
      HideSpinner(spinnerRef.current)
      localStorage.setItem('processingFlg', false)
    } else {
      rowRef.current.style.cursor = selectOperation ? Enum.CURSOR_TEXT : Enum.CURSOR_DEFAULT
      dispatch(getIndividualMonthlyLabors(staff, month, laborToggle))
      HideSpinner(spinnerRef.current)
      localStorage.setItem('processingFlg', false)
    }
  }

  // レイバー勤務場所変更
  const changeWorkPlace = async (labor, value) => {
    if (labor.workPlace === value) return null
    localStorage.setItem('processingFlg', true)
    const apiUrl = '/api/labor/changeWorkPlace'
    const data = {
      laborId: labor.laborId,
      workPlace: value,
      laborToggle: laborToggle
    }
    const result = await PutApi(apiUrl, data)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
        forceLogout()
      } else {
        alert('更新に失敗しました。')
      }
      localStorage.setItem('processingFlg', false)
    } else {
      dispatch(getIndividualMonthlyLabors(staff, month, laborToggle))
      HideSpinner(spinnerRef.current)
      localStorage.setItem('processingFlg', false)
    }
  }

  // 詳細表示用
  const [selectDetailLabor, setSelectDetailLabor] = useState() // マウスオン時レイバー

  const canWrite = !approved && grants && (grants.writeGrantDivision === 2 ||
    (grants.writeGrantDivision === 1 && loginUserInfo.warehouseId === commonSearch.warehouseId) ||
    (grants.writeGrantDivision === 3 && loginUserInfo.staffId === staff.staffId))

  // ---------------------------------------------------- マウス動作部分 start -------------------------------------------------------------

  // マウス移動
  const mouseMove = (event) => {
    event.preventDefault()
    const clientX = event.clientX
    const parentStart = Math.floor(rowRef.current.getBoundingClientRect().left) + wrapRef.current.scrollLeft
    const mousePixel = clientX + wrapRef.current.scrollLeft - parentStart - Enum.PADDING_LEFT

    if (event.nativeEvent.buttons === 0) {
      setMouseStatus('up')
      mouseUp(event)
    }

    if (mouseStatus === 'up' || event.nativeEvent.buttons === 0) {
      if (drawing !== Enum.DRAW_NONE) {
        newItemRef.current.style.display = 'none'
        if (drawing === Enum.DRAW_MOVE || drawing === Enum.DRAW_RESIZE) {
          mouseCancel()
        }
      }
      const targetItem = labors.find(item => mousePixel >= item.startIndex * Enum.COLUMN_WIDTH && mousePixel <= item.endIndex * Enum.COLUMN_WIDTH)
      if (targetItem) {
        const startPixel = targetItem.startIndex * Enum.COLUMN_WIDTH
        const endPixel = targetItem.endIndex * Enum.COLUMN_WIDTH
        const leftDiff = mousePixel - startPixel
        const rightDiff = endPixel - mousePixel
        rowRef.current.style.cursor = !canWrite ? Enum.CURSOR_NO_DROP
          : leftDiff < 5 ? Enum.CURSOR_W_RESIZE
            : rightDiff < 5 ? Enum.CURSOR_E_RESIZE
              : leftDiff > 4 && rightDiff > 4 ? Enum.CURSOR_POINTER
                : Enum.CURSOR_DEFAULT
        const index = (new Date(dateItems.dateValue)).getDate() + (allUpdateOpen ? 1 : 0) - 1
        setSelectDetailLabor({ labor: targetItem, index: index })
      } else {
        rowRef.current.style.cursor = Enum.CURSOR_DEFAULT
        setSelectDetailLabor(null)
      }
    } else if (drawing === Enum.DRAW_NEW) {
      newDraw(event, mousePixel, startIndex, editingItem, setEditingItem, newItemRef, selectOperation, rowRef, labors)
    } else if (drawing === Enum.DRAW_MOVE) {
      moveDraw(event, mousePixel, editingItem, setEditingItem, editItemRef, startDiffIndex, endDiffIndex, rowRef, labors)
    } else if (drawing === Enum.DRAW_RESIZE && (rowRef.current.style.cursor === Enum.CURSOR_W_RESIZE || rowRef.current.style.cursor === Enum.CURSOR_E_RESIZE)) {
      resizeDraw(event, mousePixel, editingItem, setEditingItem, editItemRef, rowRef, beforeItem, labors)
    }
  }

  // マウスクリック
  const mouseDown = (event) => {
    event.preventDefault()
    setMouseStatus('down')
    if (event.target.className.split(' ').find(i => i === 'menuButtons')) {
      return null
    }

    const mouseX = event.clientX
    const parentStart = Math.floor(rowRef.current.getBoundingClientRect().left) + wrapRef.current.scrollLeft
    const parentEnd = Math.floor(rowRef.current.getBoundingClientRect().right) + wrapRef.current.scrollLeft
    const mousePixel = mouseX + wrapRef.current.scrollLeft - parentStart - Enum.PADDING_LEFT
    const mouseIndex = mouseXToTaskIndex(mousePixel)

    if (
      localStorage.getItem('processingFlg') === 'true' || // 処理中
      !canWrite || // 書き込み権限がない
      mousePixel < 0 || // 範囲外は操作不可
      mousePixel > Enum.MAX_INDEX * 15 || // 範囲外は操作不可
      (spinnerRef && spinnerRef.current.style.display === 'block') // DB処理中は操作できない
    ) return null

    if (parentStart > mouseX || parentEnd < mouseX) return null // 範囲外なら何もしない
    if (event.button === Enum.BUTTON_NUM_LEFT) { // 左クリック
      // レコード上にマウスがあるか
      const targetItem = labors.find(item => mousePixel >= item.startIndex * Enum.COLUMN_WIDTH && mousePixel <= item.endIndex * Enum.COLUMN_WIDTH)
      if (!targetItem) { // 新規 工程を選んでるときのみ
        if (!selectOperation) return null
        setStartIndex(mouseIndex)
        setDrawing(Enum.DRAW_NEW)
        const newRecord = Object.assign({})
        newRecord.workDate = dateItems.dateValue
        let maxNum = 1
        labors.forEach(item => {
          if (newRecord.workDate === item.workDate && maxNum < item.branchNo) {
            maxNum = item.branchNo
          }
        })
        newRecord.staffId = staff.staffId
        newRecord.branchNo = (labors.length > 0) ? maxNum + 1 : 1
        newRecord.operationId = selectOperation.id
        newRecord.operationName = selectOperation.operationName
        newRecord.floorId = selectOperation.floorId
        newRecord.startIndex = mouseIndex
        newRecord.startPixel = mouseIndex * Enum.COLUMN_WIDTH
        newRecord.endIndex = mouseIndex + 1
        newRecord.endPixel = (mouseIndex + 1) * Enum.COLUMN_WIDTH
        newRecord.index = labors.length
        newRecord.backColor = selectOperation.backColor
        newRecord.workPlace = selectOpeWorkPlace
        newRecord.workPlaceName = selectOpeWorkPlace === 1 ? '出勤' : 'リモートワーク'
        setEditingItem(newRecord)
        return null
      }
      if (targetItem && (mousePixel - targetItem.startPixel < 5 || targetItem.endPixel - mousePixel < 5)) {
        // リサイズ
        setDrawing(Enum.DRAW_RESIZE)
        const targetIndex = labors.findIndex(item => item.branchNo === targetItem.branchNo)
        const copyItem = Object.assign({}, targetItem)
        copyItem.index = targetIndex
        setStartIndex(copyItem.startIndex)
        setBeforeItem(Object.assign({}, copyItem))
        setEditingItem(copyItem)
      } else if (targetItem && (mousePixel - targetItem.startPixel > 6 || targetItem.endPixel - mousePixel > 6)) {
        // 移動
        setDrawing(Enum.DRAW_MOVE)
        const targetIndex = labors.findIndex(item => item.branchNo === targetItem.branchNo)
        const copyItem = Object.assign({}, targetItem)
        copyItem.index = targetIndex
        setStartIndex(copyItem.startIndex)
        setBeforeItem(Object.assign({}, copyItem))
        setStartDiffIndex(mouseIndex - copyItem.startIndex)
        setEndDiffIndex(copyItem.endIndex - mouseIndex)
        setEditingItem(copyItem)
      }
    } else if (event.button === Enum.BUTTON_NUM_RIGHT) {
      setContextMenuList(null)
      contextMenuRef.current.style.display = 'none'
      // 右クリック
      const targetLabor = labors.find(labor => mousePixel > labor.startPixel && mousePixel < labor.endPixel)
      if (targetLabor) {
        setContextMenuPosition(mousePixel)
        const deleteLi = {
          name: '削除',
          component: <VariableDeleteButton name='削除' clickFunction={() => deleteLabor(targetLabor)} />
        }
        const workplace = {
          name: '勤務場所',
          isView: true,
          component: <SelectWorkPlace labor={targetLabor} changeFunction={changeWorkPlace} />
        }
        setContextMenuList([workplace, deleteLi])
        contextMenuRef.current.style.display = 'block'
      } else {
        if (rowRef.current.style.cursor === Enum.CURSOR_DEFAULT || rowRef.current.style.cursor === Enum.CURSOR_TEXT) {
          setContextMenuPosition(mousePixel)
          const allDeleteLi = {
            name: '全削除',
            component: <VariableDeleteButton name='全削除' clickFunction={() => deleteAllLabor()} />
          }
          setContextMenuList([allDeleteLi])
          contextMenuRef.current.style.display = 'block'
        }
      }
    }
  }

  // マウスアップ
  const mouseUp = (event) => {
    event.preventDefault()
    setMouseStatus('up')
    const mouseX = event.clientX
    const parentStart = Math.floor(rowRef.current.getBoundingClientRect().left) + wrapRef.current.scrollLeft
    const mousePixel = mouseX + wrapRef.current.scrollLeft - parentStart - Enum.PADDING_LEFT
    if (drawing === Enum.DRAW_NEW) {
      if (event.button !== 0) return null
      // 新規
      if (rowRef.current.style.cursor === Enum.CURSOR_POINTER) {
        ShowSpinner(spinnerRef.current, mousePixel)
        editItemRef.current[editingItem.index] = createRef()
        PostLabor()
      }
      newItemRef.current.style.display = 'none'
    } else if (drawing === Enum.DRAW_MOVE) {
      if (event.button !== 0) return null
      // 移動
      if (rowRef.current.style.cursor === Enum.CURSOR_POINTER) {
        ShowSpinner(spinnerRef.current, mousePixel)
        if (editItemRef.current[editingItem.index].current) {
          editItemRef.current[editingItem.index].current.style.left = editingItem.startPixel + Enum.PADDING_LEFT + 'px'
          editItemRef.current[editingItem.index].current.style.width = editingItem.endPixel - editingItem.startPixel + 'px'
          editingItem.startDt = editingItem.workDate + ' ' +
          ('00' + Math.floor(editingItem.startIndex * 15 / 60)).slice(-2) + ':' + ('00' + (editingItem.startIndex * 15 % 60)).slice(-2) + ':00'
          editingItem.endDt = editingItem.workDate + ' ' +
          ('00' + Math.floor(editingItem.endIndex * 15 / 60)).slice(-2) + ':' + ('00' + (editingItem.endIndex * 15 % 60)).slice(-2) + ':00'
        }
        PutLabor()
      } else if (rowRef.current.style.cursor === Enum.CURSOR_NO_DROP) {
        editItemRef.current[beforeItem.index].current.style.left = beforeItem.startPixel + Enum.PADDING_LEFT + 'px'
      }
    } else if (drawing === Enum.DRAW_RESIZE) {
      if (event.button !== 0) return null
      // リサイズ
      if (rowRef.current.style.cursor === Enum.CURSOR_E_RESIZE || rowRef.current.style.cursor === Enum.CURSOR_W_RESIZE) {
        ShowSpinner(spinnerRef.current, mousePixel)
        if (editItemRef.current[editingItem.index].current) {
          editItemRef.current[editingItem.index].current.style.left = editingItem.startPixel + Enum.PADDING_LEFT + 'px'
          editItemRef.current[editingItem.index].current.style.width = editingItem.endPixel - editingItem.startPixel + 'px'
        }
        PutLabor()
      } else if (rowRef.current.style.cursor === Enum.CURSOR_NO_DROP) {
        if (editItemRef.current[editingItem.index].current) {
          editItemRef.current[beforeItem.index].current.style.left = beforeItem.startPixel + Enum.PADDING_LEFT + 'px'
          editItemRef.current[beforeItem.index].current.style.width = beforeItem.endPixel - beforeItem.startPixel + 'px'
        }
      }
    }
    setDrawing(Enum.DRAW_NONE) // 描画終了
  }

  // マウスキャンセル
  const mouseCancel = () => {
    if (beforeItem.index) {
      if (editItemRef.current[editingItem.index].current) {
        editItemRef.current[beforeItem.index].current.style.left = beforeItem.startPixel + 'px'
        editItemRef.current[beforeItem.index].current.style.width = beforeItem.endPixel - beforeItem.startPixel + 'px'
      }
    }
    setDrawing(Enum.DRAW_NONE)
  }

  if (document.getElementById('App') != null) {
    document.getElementById('App').onclick = function (e) {
      if (e.target.className !== 'contextMenuWrap') {
        document.querySelectorAll('.contextMenuWrap').forEach(i => {
          i.style.display = 'none'
        })
      }
    }
  }

  if (wrapRef.current == null || operations == null) return null

  return (
    <WrapDiv onContextMenu={e => e.preventDefault()}>
      <RowDiv
        onMouseMove={(e) => mouseMove(e)}
        onMouseDown={(e) => mouseDown(e)}
        onMouseUp={e => mouseUp(e)}
        ref={rowRef}
        isViewDetail={selectDetailLabor != null}
        onContextMenu={e => e.preventDefault()}
      >
        <Columns
          plannedStartIndex={plannedStartIndex}
          plannedEndIndex={plannedEndIndex}
        />
        {operations && labors.map((record, index) => {
          const operation = operations.filter(ope => ope.id === record.operationId)
          const viewName = CreateName(record.endIndex, record.startIndex, record.operationName)
          editItemRef.current[index] = createRef()
          return (
            <Item
              key={record.branchNo}
              ref={editItemRef.current[index]}
              background={(operation && operation.length > 0 && operation[0].backColor) || 'rgba(111,111,111,0.5)'}
              width={(record.endIndex - record.startIndex) * Enum.COLUMN_WIDTH}
              left={record.startIndex * Enum.COLUMN_WIDTH + Enum.PADDING_LEFT}
              mouseStatus={mouseStatus}
            >
              {viewName}
            </Item>
          )
        })}
        <LaborDetailPop
          selectDetailLabor={selectDetailLabor}
          wrapRef={wrapRef}
        />
        <Item display='none' ref={newItemRef} />
        <DotSpinner spinnerColor={Enum.SPINNER_COLOR} ref={spinnerRef} />
      </RowDiv>
      <ContextMenuWrap ref={contextMenuRef} className='contextMenuWrap'>
        <ContextMenu leftPosition={contextMenuPosition} menuList={contextMenuList} />
      </ContextMenuWrap>
    </WrapDiv>
  )
}

RowRight.propTypes = {
  laborToggle: PropTypes.string,
  month: PropTypes.string,
  allUpdateOpen: PropTypes.bool,
  staff: PropTypes.object,
  dateItems: PropTypes.object,
  grants: PropTypes.object,
  staffId: PropTypes.string,
  wrapRef: PropTypes.any,
  approved: PropTypes.bool
}

export default RowRight
