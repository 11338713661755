import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FaChevronLeft, FaChevronRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa'

const PaginationWrap = styled.ul`
  display: flex;
  width: 15vw;
  justify-content: space-between;
  list-style: none;
  ${props => props.addStyle}
`

const Arrow = styled.li`
  height: 35px;
  width: 1vw;
  line-height: 37px;
  text-align: center;
  background: #ddd;
  color: #333;
  cursor: pointer;
  border-radius: ${props => props.radius};
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.25);
  :hover {
    background: #ccc;
    color: #333;
  }
`

const Brank = styled.li`
  height: 35px;
  width: 2vw;
`
const ThreeDot = styled.li`
  height: 35px;
  width: 2vw;
  line-height: 35px;
  list-style: none;
  text-align: center;
  color: white;
`

const ListUl = styled.ul`
  display: flex;
  justify-content: center;
  width: 10vw;
`

const ListLi = styled.li`
  height: 35px;
  width: 2vw;
  line-height: 35px;
  text-align: center;
  background: ${props => props.selected ? '#649CDF' : '#ddd'};
  color: ${props => props.selected ? '#fff' : '#333'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  border-right: 1px solid #aaa;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.25);
  :hover {
    ${props => !props.selected ? 'background: #ccc; color: #333;' : ''}
  }
  :last-of-type {
    border-right: none;
  }
`

const Pagination = ({ pagination, pageSelectFunc, addStyle }) => {
  const { endPage, selectedPage } = pagination
  var pageList = []
  if (endPage > 3 && selectedPage >= 3) pageList.push(<ThreeDot key='startThreeDot'>...</ThreeDot>)
  for (let i = 1; i <= endPage; i++) {
    if (
      endPage < 4 ||
      (selectedPage === 1 && i < 4) ||
      (selectedPage === endPage && i > selectedPage - 3) ||
      (i === selectedPage - 1 || i === selectedPage || i === selectedPage + 1)
    ) {
      pageList.push(
        <ListLi
          onClick={() => pageSelectFunc(i)}
          selected={i === selectedPage - 0}
          value={i}
          key={i}
        >
          {i}
        </ListLi>
      )
    }
  }
  if (endPage > 3 && selectedPage <= 3) pageList.push(<ThreeDot key='endThreeDot'>...</ThreeDot>)
  const LeftParts = () => {
    if (endPage < 4 || selectedPage === 1) return <Brank />
    return (
      <>
        <Arrow radius='5px 0 0 5px' onClick={() => pageSelectFunc(1)}>
          <FaAngleDoubleLeft />
        </Arrow>
        <Arrow radius='0' onClick={() => pageSelectFunc(selectedPage - 1)}>
          <FaChevronLeft />
        </Arrow>
      </>
    )
  }
  const RightParts = () => {
    if (endPage < 4 || selectedPage === endPage) return <Brank />
    return (
      <>
        <Arrow radius='0' onClick={() => pageSelectFunc(selectedPage + 1)}>
          <FaChevronRight />
        </Arrow>
        <Arrow radius='0 5px 5px 0' onClick={() => pageSelectFunc(endPage)}>
          <FaAngleDoubleRight />
        </Arrow>
      </>
    )
  }
  return (
    <PaginationWrap addStyle={addStyle}>
      <LeftParts />
      <li>
        <ListUl>
          {pageList}
        </ListUl>
      </li>
      <RightParts />
    </PaginationWrap>
  )
}

Pagination.propTypes = {
  pagination: PropTypes.object,
  pageSelectFunc: PropTypes.func,
  addStyle: PropTypes.string
}

export default Pagination
