import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useAlert } from 'react-alert'
import { IconContext } from 'react-icons'
import { FaDownload } from 'react-icons/fa'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { yellow } from '@material-ui/core/colors'

import { PostApi, DeleteApi } from '../../Common/ApiAxios'
import { cell, icon, WithOverTimeToolTip, overtimeReasonExists, getOvertimeReason } from './helper'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: '1 1 100%'
  },
  label: {
    fontWeight: 'bold',
    fontSize: '0.8rem'
  }
}))

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles()
  const { grants, handleUpdate, handleCsvDownloadClick, title = '' } = props

  return (
    <Toolbar
      className={classes.root}
    >
      <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
        {title}
      </Typography>

      {handleCsvDownloadClick && (
        <Button
          aria-label='Approval'
          onClick={() => handleCsvDownloadClick(true)}
          variant='contained'
          color='primary'
          style={{ marginRight: 8 }}
        >
          <IconContext.Provider value={{ color: 'white' }}>
            <FaDownload style={{ marginRight: 4 }} size={16} />
          </IconContext.Provider>
        CSV
        </Button>
      )}
      {
        // 書き込み権限が自分のみの場合は更新不可にしておく
        grants && (grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1)
          ? (
            <Button
              aria-label='Approval'
              onClick={() => handleUpdate()}
              variant='contained'
              color='primary'
            >
              更新
            </Button>
          )
          : null
      }
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  handleUpdate: PropTypes.func,
  title: PropTypes.string,
  grants: PropTypes.object
}
/**
 * Header
 * @param {*} props
 */
function LaborApprovalTableHead (props) {
  const { grants, headCells, onSelectAllClick, numSelected, rowCount } = props
  const classes = useToolbarStyles()

  const createHeadCell = (headCell) => {
    return (
      <div>

        {
          headCell.label.split(' ').map((h, idx) => <p key={idx}>{h}<br /></p>)
        }

      </div>
    )
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'default'}
            className={classes.tableCell}
            style={{ fontWeight: 'bold', align: 'center' }}
          >
            {
              createHeadCell(headCell)
            }
          </TableCell>
        ))}
        <TableCell padding='checkbox'>
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            }
            disabled={!(grants && (grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1))}
            label='承認'
            labelPlacement='end'
            classes={{ label: classes.label }}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

LaborApprovalTableHead.propTypes = {
  headCells: PropTypes.array,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  grants: PropTypes.object
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: yellow[100]
    }
  },
  tableCell: {
    paddingRight: 5
  },
  customTooltip: {
    margin: 5,
    // backgroundColor: '#FFF9C4',
    // backgroundColor: '#f5f5f5',
    // color: '#000',
    fontSize: '0.8em'
  }
}))

/**
 * テーブル本体
 */
export default function LaborApprovalTable (props) {
  const alert = useAlert()
  const {
    grants, approvedLabor, overtimeInfo, headCells, rows,
    handleDetailClick = f => f,
    handleCsvDownloadClick = f => f
    , staffId = null
  } = props
  const classes = useStyles()
  const { loginUserInfo } = useSelector(state => state)

  // 承認状態更新
  const handleUpdate = async () => {
    // 承認、承認解除状態を取得
    let approvedData = []
    let unapprovedData = []
    // TODO replace to foreach
    // eslint-disable-next-line
    rows.map(r => {
      // 未承認→承認
      if (r.approvedDate === '') {
        if (selected.includes(r.dateValue)) {
          approvedData = approvedData.concat({
            staffId: staffId,
            approvedDate: r.dateValue,
            approvedStaffId: loginUserInfo.staffId,
            createdId: loginUserInfo.staffId,
            updatedId: loginUserInfo.staffId
          })
        } else if (selected.includes(r.staffId)) {
          // console.log('🚀 ~ div approval:', r)
          approvedData = approvedData.concat({
            staffId: r.staffId,
            approvedDate: props.title, // 日付
            approvedStaffId: loginUserInfo.staffId,
            createdId: loginUserInfo.staffId,
            updatedId: loginUserInfo.staffId
          })
        }
      // 承認→未承認
      } else {
        const del = r.dateValue
          ? (!selected.includes(r.dateValue) && !selected.includes(r.staffId)) && approvedLabor.filter(a => a.approvedDate === r.dateValue)
          : !selected.includes(r.staffId) && approvedLabor.filter(a => a.staffId === r.staffId)

        if (del.length > 0) {
          unapprovedData = unapprovedData.concat({ id: del[0].id })
        }
      }
    })

    // console.log('🚀 ~ file: SortableTable.js ~ line 172 ~ handleUpdate ~ selected', selected)
    if (approvedData.length === 0 && unapprovedData.length === 0) {
      alert.info('更新対象がありません')
      return null
    }
    const apiUrl = '/api/labor/approved'
    let postResult = null
    let deleteResult = null
    if (approvedData.length > 0) {
      postResult = await PostApi(
        apiUrl,
        { approvedLabors: approvedData },
        true
      )
    }

    if (unapprovedData.length > 0) {
      deleteResult = await DeleteApi(apiUrl, { ids: unapprovedData }, true)
    }

    if ((postResult == null || postResult.status === 200) &&
      (deleteResult == null || deleteResult.status === 200)) {
      alert.success('更新しました。')
      props.setUpdateTime(Date.now())
    } else {
      alert.error('更新に失敗しました。')
    }
  }

  const [selected, setSelected] = React.useState([])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (name) => () => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  useEffect(() => {
    const selectedItems = rows
      .filter(r => r.approvedDate !== '')
      .map(a => a.id)
    setSelected(selectedItems)
  }, [rows])

  const isSelected = (id) => selected.indexOf(id) !== -1

  // console.log('🚀 ~ file: SortableTable.js ~ line 154 ~ SortableTable ~ approvedLabor', approvedLabor)
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={props.title}
          handleUpdate={handleUpdate}
          handleCsvDownloadClick={handleCsvDownloadClick}
          grants={grants}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size='small'
            aria-label='enhanced table'
          >
            <LaborApprovalTableHead
              classes={classes}
              headCells={headCells}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              grants={grants}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${index}`
                return (
                  <TableRow
                    hover
                    onClick={
                      (grants &&
                        ((grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1))
                      )
                        ? handleClick(row.id)
                        : null
                    }
                    tabIndex={-1}
                    key={row.id}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    className={isItemSelected ? classes.tableRow : classes.tableRow}
                  >
                    {
                      row && Object.keys(row).map((key, _index) => {
                        const dataHeader = headCells.filter(h => h.id === key)
                        // console.log('=== overtimeReason', overtimeReason)
                        if (dataHeader.length > 0) {
                          return (
                            <React.Fragment key={_index}>
                              {
                                // 残業があるもののみ
                                dataHeader[0].label === '残業 申請' && row[key] !== 0
                                  ? (Object.keys(row).includes('dateValue')
                                    ? overtimeReasonExists(overtimeInfo, row.staffId, row.dateValue)
                                    : overtimeReasonExists(overtimeInfo, row.staffId)) ? (
                                      <WithOverTimeToolTip
                                        classes={classes}
                                        overtimeReason={getOvertimeReason(overtimeInfo, row.staffId, row.dateValue)}
                                      >
                                        {icon(classes, _index, dataHeader[0])}
                                      </WithOverTimeToolTip>
                                    )
                                    : <TableCell /> : (
                                    cell(classes, _index, dataHeader[0], row, key)
                                  )

                              }
                            </React.Fragment>
                          )
                        }
                      })
                    }
                    <TableCell padding='checkbox'>
                      <Checkbox
                        disabled={!(grants && (grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1))}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell padding='checkbox'>
                      {row.startDt != null &&
                          (
                            <Button
                              color='primary'
                              variant='contained'
                              data-value='aaa'
                              onClick={handleDetailClick(row.id)}
                            >
                              詳細
                            </Button>
                          )}
                    </TableCell>

                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

LaborApprovalTable.propTypes = {
  title: PropTypes.string,
  approvedLabor: PropTypes.array.isRequired,
  overtimeInfo: PropTypes.array,
  headCells: PropTypes.array,
  rows: PropTypes.array.isRequired,
  handleDetailClick: PropTypes.func.isRequired,
  staffId: PropTypes.string,
  setUpdateTime: PropTypes.func.isRequired,
  grants: PropTypes.object
}
