/* eslint-env browser */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOperation, LaborStates } from '../LaborSlice'
import { GetApi } from '../../Common/ApiAxios'
import OperationList from '../OperationList'
import RecentOperationList from '../RecentOperationList'
import FavoriteOperationList from '../FavoriteOperationList'
import FUNC from '../../Common/FunctionEnum'
import Operations from '../../Common/Molecules/SideMenu'
import PropTypes from 'prop-types'
import TabSelector from '../../Common/Atoms/TabSelector'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import styled from 'styled-components'

const SideMenu = (props) => {
  const labor = LaborStates()
  const isPlanned = props.isPlanned
  const dispatch = useDispatch()
  const [companyList, setCompanyList] = useState([]) // ゾーンまでそろっている会社情報取得
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const [tabWorkPlace, setTabWorkPlace] = useState(() => { return labor.selectOpeWorkPlace != null ? labor.selectOpeWorkPlace : 1 })
  const tabWorkPlaceList = [
    { value: 1, name: '出勤' },
    { value: 2, name: 'リモートワーク' }
  ]
  const [laborTabValue, setLaborTabValue] = useState(0)

  const grants = loginUserInfo.menuList &&
    loginUserInfo.menuList.find(menu => menu.functionMenuId === (isPlanned ? FUNC.PLANNED_LABOR : FUNC.RESULT_LABOR))

  const canViewOperationList = grants && (
    grants.writeGrantDivision === 2 ||
    (grants.writeGrantDivision === 1 && commonSearch.warehouseId === loginUserInfo.warehouseId) ||
    (grants.writeGrantDivision === 3 && commonSearch.warehouseId === loginUserInfo.warehouseId)
  )

  // 会社情報一覧
  const onCompanyInfoSearch = async () => {
    const data = {
      notBlankStatus: 0 // 会社-倉庫-フロア-ゾーンが欠損なしのレコード
    }
    const result = await GetApi('/api/companyInfo', data)
    if ('errorDetail' in result) return console.error('error')
    if (result.data.length === 0) {
      console.error('必要データが揃っていません')
    }
    setCompanyList(result.data)
  }

  useEffect(() => {
    if (loginUserInfo.companyId) {
      onCompanyInfoSearch()
    }
  }, [loginUserInfo])

  useEffect(() => {
    if (commonSearch.companyId) {
      dispatch(setOperation(null))
    }
  }, [commonSearch, dispatch])

  const SelectFilterWrap = styled.div`
    height: 35px;
    color: #fff;
    margin: 12px 16px;
  `
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const handleChange = (event, newValue) => {
    setLaborTabValue(newValue)
  }

  if (!grants) return null
  return (
    <Operations isView={canViewOperationList} width='300px'>
      <AppBar position='static'>
        <Tabs style={{ backgroundColor: '#0000FF' }} value={laborTabValue} onChange={handleChange} aria-label='operationSelectTab'>
          <Tab style={{ minWidth: 95, fontWeight: 'bold' }} label='工程検索' {...a11yProps(0)} />
          <Tab style={{ minWidth: 95, fontWeight: 'bold' }} label='最近の工程' {...a11yProps(1)} />
          <Tab style={{ minWidth: 95, fontWeight: 'bold' }} label='マイ工程' {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SelectFilterWrap>
        <TabSelector
          selectValue={tabWorkPlace}
          setSelectValue={setTabWorkPlace}
          tabsName='commonOpeWorkPlace'
          dataList={tabWorkPlaceList}
          selectedBackColor='lime'
          selectedColor='black'
        />
      </SelectFilterWrap>
      <div style={laborTabValue === 0 ? {} : { display: 'none' }}>
        <OperationList companyList={companyList} />
      </div>
      <div style={laborTabValue === 1 ? {} : { display: 'none' }}>
        <RecentOperationList companyList={companyList} loginUserInfo={loginUserInfo} />
      </div>
      <div style={laborTabValue === 2 ? {} : { display: 'none' }}>
        <FavoriteOperationList companyList={companyList} loginUserInfo={loginUserInfo} />
      </div>
    </Operations>
  )
}
SideMenu.propTypes = {
  isPlanned: PropTypes.bool
}

export default SideMenu
