/* eslint-env browser */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { LaborStates, setOperation, apiGetFavoriteOperations, apiDeleteFavoriteOperations } from './LaborSlice'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FaTrash } from 'react-icons/fa'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const SelectFilterWrap = styled.div`
  color: #fff;
  margin: 12px 16px;
`
const OperationsUl = styled.ul`
  height: calc(100vh - 210px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    display:none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`

const PopSpan = styled.span`
  display: ${props => props.display};
  top: ${props => props.top}px;
  left: 10px;
  color: #000;
  background: #f8fdb0;
  padding: 10px 12px;
  border-radius: 5px;
  pointer-events: none;
  position: absolute;
  z-index: 6;
  list-style: none;
  font-size: 14px;
  width: 280px;
  word-wrap: break-word;
`

// 工程詳細表示条件 useStateはなるべくまとめるとEffect1かいで済む
const SelectFilter = ({ loginUserInfo }) => {
  const labor = LaborStates()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(apiGetFavoriteOperations(loginUserInfo.staffId))
  }, [dispatch, labor.labors, loginUserInfo.staffId])

  return (
    <SelectFilterWrap />
  )
}

SelectFilter.propTypes = {
  companyList: PropTypes.array
}

const OperationItem = ({ operation, onMouse, index, loginUserInfo }) => {
  const dispatch = useDispatch()
  const [style, setStyle] = useState({
    backgroundColor: '',
    fontColor: 'white',
    fontSize: '12px',
    border: '',
    boxSizing: 'border-box',
    width: '160px',
    height: '32px',
    margin: '0 auto 10px',
    textAlign: 'center',
    lineHeight: '28px',
    textIndent: '5px',
    color: '#fff',
    borderRadius: '5px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  })
  const labor = LaborStates()
  const selectOperation = labor.selectOperation

  useEffect(() => {
    const newStyle = Object.assign({}, style)
    if (selectOperation && selectOperation.id === operation.operationId) {
      newStyle.backgroundColor = operation.backColor
    } else {
      newStyle.backgroundColor = ''
    }
    newStyle.border = '1px solid ' + operation.backColor
    setStyle(newStyle)
    // eslint-disable-next-line
  }, [selectOperation, operation])

  const onClickOperation = useCallback((operation) => {
    const newStyle = Object.assign({}, style)

    if (selectOperation && selectOperation.id === operation.operationId) {
      newStyle.backgroundColor = ''
      dispatch(setOperation(null))
    } else {
      newStyle.backgroundColor = operation.backColor

      var favObj = Object.assign({}, operation)
      favObj.id = favObj.operationId
      dispatch(setOperation(favObj))
    }
    setStyle(newStyle)
  })

  const deleteFavoriteOperation = (operationId) => {
    const postData = {
      staffId: loginUserInfo.staffId,
      operationId: operationId,
      sort: 0
    }
    dispatch(apiDeleteFavoriteOperations(postData))
  }

  return (
    <Draggable key={`favoraite-${operation.id}`} draggableId={`favoraite-${operation.id}`} index={index} type='list'>
      {(provided) => (

        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            width: '200px',
            margin: '0 auto 10px',
            lineHeight: '28px',
            textIndent: '5px',
            listStyle: 'none'
          }}
        >
          <div style={{ display: 'flex', zIndex: '10000' }}>
            <div
              style={style}
              onClick={() => onClickOperation(operation)}
              onMouseEnter={() => onMouse(index, operation)}
              onMouseLeave={() => onMouse(index, null)}
            >
              {operation.operationName}
            </div>
            <div>
              <div onClick={() => { deleteFavoriteOperation(operation.operationId) }}> <FaTrash style={{ color: '#FF0000' }} /> </div>
            </div>
          </div>
        </li>
      )}
    </Draggable>
  )
}

OperationItem.propTypes = {
  operation: PropTypes.object,
  onMouse: PropTypes.func
}

// 最近使用した工程一覧描写
const FavoriteOperationList = ({ companyList, loginUserInfo }) => {
  const labor = LaborStates()
  const ulRef = useRef()
  const operations = labor.favoriteOperations
  const [popOperation, setPopOperation] = useState()

  const OperationPop = useCallback(() => {
    return (
      <PopSpan display={popOperation ? 'block' : 'none'} top={popOperation?.top}>
        <div style={{ marginBottom: '5px' }}>フロア：{popOperation?.floorName} </div>
        <div style={{ marginBottom: '5px' }}>ゾーン：{popOperation?.zoneName} </div>
        <div style={{ marginBottom: '5px' }}>カテゴリー：{popOperation?.categoryName}</div>
        <div>工程名：{popOperation?.operationName}</div>
      </PopSpan>
    )
  }, [popOperation])

  const onMouse = (index, value) => {
    if (value) {
      const value_ = {
        floorName: value.floorName,
        zoneName: value.zoneName,
        categoryName: value.categoryName,
        operationName: value.operationName,
        top: index * 42 + 85 - ulRef.current.scrollTop
      }
      setPopOperation(value_)
    } else {
      setPopOperation(null)
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <SelectFilter companyList={companyList} loginUserInfo={loginUserInfo} labor={labor} />
      <DragDropContext>
        <Droppable droppableId='favoraite'>
          {(provided) => (
            <div ref={ulRef}>
              <OperationsUl className='favoraite' {...provided.droppableProps} ref={provided.innerRef}>
                {operations && operations.map(
                  (operation, index) => {
                    return (
                      <OperationItem key={operation.id} operation={operation} onMouse={onMouse} index={index} loginUserInfo={loginUserInfo} />
                    )
                  })}
                {provided.placeholder}
              </OperationsUl>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <OperationPop />
    </div>
  )
}

FavoriteOperationList.propTypes = {
  companyList: PropTypes.array
}

export default FavoriteOperationList
