import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { defaultUrl } from './Role'
import { PostApi, DeleteApi, PutApi } from '../../Common/ApiAxios'
import { useForm } from 'react-hook-form'
import { InputGroup, HiddenInput, SelectGroup } from '../Common/CommonInputGroup'
import MasterDetailWrap from '../../Common/Wrap/MasterDetailWrap'
import Button from '../../Common/Atoms/NormalButton'
import ErrorHandling from '../Common/ErrorHandling'
import * as regex from '../../Common/Regex'
import { useAlert } from 'react-alert'
import { FaTruck, FaAward, FaElementor, FaCrown, FaUsers } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import { setValues } from '../../Common/utils'
import MasterDetailButtons from '../../Common/Wrap/MasterDetailButtons'
import PropTypes from 'prop-types'
import CreaterInfo from '../../Common/Molecules/CreaterInfo'

const Form = styled.form`
  width: 100%;
  height: 100%;
`

const IconRadioGroup = styled.div`
  width: 320px;
  height: 48px;
  display: flex;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #b6b6b6;
  div {
    position: relative;
    flex: 1;
    &:hover {
      input:not(:checked) + label {
        color: gray;
      }
    }
  }
  input {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  label {
    position: absolute;
    top: 0; left: 0;
    color: #b6b6b6;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    border-right: 1px solid #b6b6b6;
  }
  div:last-child label {
    border-right: 0;
  }
  input:checked + label {
    background: #649CDF;
    color: #fff;
  }
`

const RoleMasterForm = ({ register, errors, companyList, setValue, detail, canWrite, grants, loginUserInfo }) => {
  // 会社一覧
  const companies = useMemo(() => companyList.map(d => {
    if (grants.writeGrantDivision === 1 &&
      (
        (!detail.warehouseId && d.companyId !== loginUserInfo.companyId) ||
        (detail.warehouseId && detail.companyId === loginUserInfo.companyId && d.companyId !== loginUserInfo.companyId)
      )
    ) return null
    return { companyId: d.companyId, companyName: d.companyName }
  }), [companyList, detail, grants, loginUserInfo.companyId])
  // オプション生成
  const companyOptions = useMemo(() => companies.map(d => {
    if (d === null) return null
    return <option key={d.companyId} value={d.companyId}>{d.companyName}</option>
  }), [companies])
  // 状態管理
  const [selectedCompanyId, setSelectedCompanyId] = useState(null)
  // 変更イベント
  const onChangeCompany = useCallback((e) => { setSelectedCompanyId(e.target.value) }, [])
  // 初期化処理
  useEffect(() => {
    detail
      ? setSelectedCompanyId(detail && detail.companyId)
      : companies.length === 1 && setSelectedCompanyId(companies[0].companyId)
  }, [detail, companies])

  // 倉庫一覧
  const warehouses = useMemo(() =>
    companyList
      .find(d => d.companyId === selectedCompanyId)
      ?.warehouses
      .map(d => {
        if (grants.writeGrantDivision === 1 &&
          (
            (!detail.warehouseId && d.warehouseId !== loginUserInfo.warehouseId) ||
            (detail.warehouseId && detail.warehouseId === loginUserInfo.warehouseId && d.warehouseId !== loginUserInfo.warehouseId)
          )
        ) return null
        return ({ warehouseId: d.warehouseId, warehouseName: d.warehouseName })
      }) || []
  , [selectedCompanyId, companyList, detail, grants, loginUserInfo.warehouseId])
  // オプション生成
  const warehouseOptions = useMemo(() => warehouses.map(d => {
    if (d === null) return null
    return <option key={d.warehouseId} value={d.warehouseId}>{d.warehouseName}</option>
  }), [warehouses])
  // 初期化処理
  useEffect(() => {
    detail?.warehouseId && setValue('warehouseId', detail.warehouseId, true)
  }, [detail, selectedCompanyId, setValue])

  // ラジオボタン状態管理
  useEffect(() => { register({ name: 'iconIndex' }) }, [register])
  const [iconIndex, setIconIndex] = useState(null)
  // 初期化処理
  useEffect(() => {
    setIconIndex(`${detail?.iconIndex}` || null)
  }, [detail])
  // クリックイベント
  const onClickIcon = useCallback((e) => {
    const newState = iconIndex === e.currentTarget.value ? null : e.currentTarget.value
    e.currentTarget.checked = newState
    setValue('iconIndex', newState, true)
    setIconIndex(newState)
  }, [iconIndex, setIconIndex, setValue])
  return (
    <MasterDetailWrap isView='true'>
      <ul>
        <li style={{ height: 15 }}><label /><div /></li>
        <li>
          <label>ID</label>
          <InputGroup
            width='320px'
            margin='0'
            disabled
            placeholder='自動採番'
            name='id'
            register={register}
            rule={{ maxLength: regex.maxLength(10) }}
            errors={errors}
          />
        </li>
        <li>
          <label style={{ position: 'relative' }}>名称<span style={{ color: 'red', position: 'absolute' }}>*</span></label>
          <div>
            <InputGroup
              width='320px'
              margin='0'
              placeholder='役割名'
              name='roleName'
              register={register}
              rule={{ required: regex.required, maxLength: regex.maxLength(50) }}
              errors={errors}
              disabled={!canWrite}
            />
          </div>
        </li>
        <li>
          <label>アイコン</label>
          <div>
            <IconContext.Provider value={{ style: { verticalAlign: 'text-bottom', width: 30, height: 30 } }}>
              <IconRadioGroup>
                <div>
                  <input
                    type='radio' id='icon-1' name='iconIndex'
                    value='1'
                    defaultChecked={iconIndex === '1'}
                    onClick={onClickIcon}
                    disabled={grants.writeGrantDivision === 0 || grants.writeGrantDivision === 3}
                  />
                  <label htmlFor='icon-1'><FaCrown /></label>
                </div>
                <div>
                  <input
                    type='radio' id='icon-2' name='iconIndex'
                    value='2'
                    defaultChecked={iconIndex === '2'}
                    onClick={onClickIcon}
                    disabled={grants.writeGrantDivision === 0 || grants.writeGrantDivision === 3}
                  />
                  <label htmlFor='icon-2'><FaAward /></label>
                </div>
                <div>
                  <input
                    type='radio' id='icon-3' name='iconIndex'
                    value='3'
                    defaultChecked={iconIndex === '3'}
                    onClick={onClickIcon}
                    disabled={grants.writeGrantDivision === 0 || grants.writeGrantDivision === 3}
                  />
                  <label htmlFor='icon-3'><FaUsers /></label>
                </div>
                <div>
                  <input
                    type='radio' id='icon-4' name='iconIndex'
                    value='4'
                    defaultChecked={iconIndex === '4'}
                    onClick={onClickIcon}
                    disabled={grants.writeGrantDivision === 0 || grants.writeGrantDivision === 3}
                  />
                  <label htmlFor='icon-4'><FaTruck /></label>
                </div>
                <div>
                  <input
                    type='radio' id='icon-5' name='iconIndex'
                    value='5'
                    defaultChecked={iconIndex === '5'}
                    onClick={onClickIcon}
                    disabled={grants.writeGrantDivision === 0 || grants.writeGrantDivision === 3}
                  />
                  <label htmlFor='icon-5'><FaElementor /></label>
                </div>
              </IconRadioGroup>
            </IconContext.Provider>
          </div>
        </li>
        <li>
          <label>会社</label>
          <div>
            <SelectGroup
              margin='0' width='320px' padding='unset'
              placeholder='選択してください'
              name='companyId'
              register={register}
              rule={{ required: regex.required }}
              errors={errors}
              options={companyOptions}
              onChange={onChangeCompany}
              disabled={!canWrite}
            />
          </div>
        </li>
        <li>
          <label>倉庫</label>
          <div>
            <SelectGroup
              margin='0' width='320px' padding='unset'
              placeholder='選択してください'
              name='warehouseId'
              register={register}
              rule={{ required: regex.required }}
              errors={errors}
              options={warehouseOptions}
              disabled={!canWrite}
            />
          </div>
        </li>
        <HiddenInput
          name='updatedId'
          register={register}
        />
        <HiddenInput
          name='updatedAt'
          register={register}
        />
        <li style={{ height: 15 }}><label /><div /></li>
      </ul>
    </MasterDetailWrap>
  )
}

RoleMasterForm.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  companyList: PropTypes.array,
  setValue: PropTypes.func,
  detail: PropTypes.object,
  isDeleted: PropTypes.bool,
  canWrite: PropTypes.bool
}

const RoleDetails = ({ detail, setDetail, onSearch, companyList, setSelectedId, canWrite, grants }) => {
  const { register, handleSubmit, setValue, errors, reset } = useForm({ validateCriteriaMode: 'all' })
  const [actionType, setActionType] = useState('POST')
  const alert = useAlert()

  const loginUserInfo = useSelector(state => state.loginUserInfo)

  // 物量情報をFormに反映
  useEffect(() => {
    if (!detail || !('id' in detail)) {
      setActionType('POST')
      reset()
    } else {
      setActionType('PUT')
      setValues(detail, setValue)
    }
  }, [detail, reset, setValue])

  // POST・PUT処理
  const onSubmit = (data, e) => {
    async function sendPost (requestBody) {
      var result
      switch (actionType) {
        case 'POST':
          result = await PostApi(`${defaultUrl}`, requestBody, true)
          break
        case 'PUT':
          result = await PutApi(`${defaultUrl}/${requestBody.id}`,
            Object.assign(requestBody, { lastUpdatedAt: requestBody.updatedAt }
            ), true)
          break
        default:
          result = false
          break
      }
      if (!ErrorHandling(result, alert)) {
        setDetail({})
        setSelectedId(null)
        onSearch(null)
        setActionType('POST')
        alert.success('保存しました')
      }
    }
    sendPost(data)
  }

  // DELETEはForm状態に影響されないため個別で定義
  const onClickHandleDelete = (e) => {
    async function sendDelete (quantityId, data) {
      const result = await DeleteApi(`${defaultUrl}/${quantityId}`, data, true)
      if (!ErrorHandling(result, alert)) {
        setDetail(null)
        setActionType('POST')
        onSearch()
        alert.info('削除しました')
      }
    }
    if (detail.id !== undefined) {
      sendDelete(detail.id, { updatedAt: detail.updatedAt })
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <RoleMasterForm
          register={register}
          errors={errors}
          setValue={setValue}
          detail={detail}
          companyList={companyList}
          canWrite={canWrite}
          grants={grants}
          loginUserInfo={loginUserInfo}
        />
        <MasterDetailButtons style={{ display: canWrite ? 'flex' : 'none' }}>
          <Button
            name={actionType === 'PUT' ? '更新' : '登録'}
            height='20px'
            width='90px'
            padding='0'
            type='submit'
          />
          {detail?.delFlag !== undefined && !detail?.delFlag &&
            <Button
              display='none' // 第一フェーズでは非表示
              height='20px'
              width='90px'
              padding='0'
              name='削除'
              type='button'
              onClick={(e) => onClickHandleDelete(e)}
            />}
        </MasterDetailButtons>
        <CreaterInfo info={detail} />
      </Form>
    </>
  )
}

RoleDetails.propTypes = {
  grants: PropTypes.object,
  canWrite: PropTypes.bool,
  detail: PropTypes.object,
  setDetail: PropTypes.func,
  onSearch: PropTypes.func,
  setSelectedId: PropTypes.func,
  companyList: PropTypes.array
}

export default RoleDetails
