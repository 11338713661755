import React from 'react'

import styled from 'styled-components'
import { DateTime } from 'luxon'
import { TableCell, Tooltip } from '@material-ui/core'
import { IconContext } from 'react-icons'
import { FaStackOverflow } from 'react-icons/fa'

// export const buildDivisionLabors = (staff, staffLabors, laborApproval, workDate, attendance) => {
export const buildDivisionLabors = (staff, staffLabors, laborApproval, workDate, plannedAttendance) => {
  const approval = laborApproval?.filter(
    a => a.approvedDate === workDate && a.staffId === staffLabors[0].staffId
  )
  const minLabor = getTime(staffLabors, getMin)
  const minTime = minLabor.startDt
  const maxTime = getTime(staffLabors, getMax).endDt
  const attendance = plannedAttendance?.filter(a => a.staffId === staff.staffId && a.workDt === workDate)
  const breakOpes = staffLabors.filter(l => l.operationName === '休憩')
  const breakHours = breakOpes.reduce((acc, cur) => acc + (cur.endIndex - cur.startIndex), 0)
  const breakHoursInNightShift = getBreakHoursInNightShift(breakOpes, minLabor.workDate)
  const startDt = !minTime ? null : getTimeString(minTime)
  const endDt = !maxTime ? null : getTimeString(maxTime)
  return {
    staffId: staff.staffId,
    staffName: staffLabors[0]?.staffName.replace('　', '').replace(' ', ''),
    plannedStartDt: attendance.length ? getTimeString(attendance[0].startDt) : null,
    plannedEndDt: attendance.length ? getTimeString(attendance[0].endDt) : null,
    startDt: startDt,
    endDt: endDt,
    workHours: staff.hours,
    breakHours: breakHours * 15 / 60,
    overTime: staff.hours < 8 ? 0 : staff.hours - 8.0,
    nightTime: (minTime && maxTime) ? calcNightHours(minTime, maxTime, minLabor.workDate) - breakHoursInNightShift : 0,
    overtimeReason: '',
    approved: approval.length > 0 ? '承認済' : '未承認',
    approvedDate: approval.length > 0 ? getDateTimeString(approval[0].createdAt) : '',
    approvedStaff: approval.length > 0 ? approval[0].approvedStaffName.replace('　', '').replace(' ', '') : '',
    id: staff.staffId
  }
}

export const buildPersonalLabors = (l, laborApproval, plannedAttendance) => {
  const lbr = Object.assign({}, laborTemplate)
  // console.log(d)
  const breakOpes = l.labors.filter(l => l.operationName === '休憩')
  const breakHours = breakOpes.reduce((acc, cur) => acc + (cur.endIndex - cur.startIndex), 0)
  const minTime = getTime(l.labors, getMin).startDt
  const maxTime = getTime(l.labors, getMax).endDt
  const attendance = plannedAttendance?.filter(a => a.workDt === l.dateValue)
  const breakHoursInNightShift = getBreakHoursInNightShift(breakOpes, l.dateValue)
  const approval = laborApproval ? laborApproval.filter(a => a.approvedDate === l.dateValue) : []
  const startDt = !minTime ? null : getTimeString(minTime)
  const endDt = !maxTime ? null : getTimeString(maxTime)
  lbr.dateValue = l.dateValue
  lbr.week = l.week
  lbr.plannedStartDt = attendance.length ? getTimeString(attendance[0].startDt) : null
  lbr.plannedEndDt = attendance.length ? getTimeString(attendance[0].endDt) : null
  lbr.staffId = l.labors.length > 0 ? l.labors[0].staffId : null
  lbr.startDt = startDt
  lbr.endDt = endDt
  lbr.sumHour = l.sumHour.replace('H', '')
  lbr.breakHours = breakHours * 15 / 60
  lbr.overTime = lbr.sumHour < 8 ? 0 : lbr.sumHour - 8.0
  lbr.nightTime = (minTime && maxTime) ? calcNightHours(minTime, maxTime, l.dateValue) - breakHoursInNightShift : 0
  lbr.overtimeReason = ''
  lbr.approved = approval.length > 0 ? '承認済' : '未承認'
  lbr.approvedDate = approval.length > 0 ? getDateTimeString(approval[0].createdAt) : ''
  lbr.approvedStaff = approval.length > 0 ? approval[0].approvedStaffName : ''
  lbr.holiday = l.holiday
  lbr.id = l.dateValue
  return lbr
}

export const buildUnapprovedLabors = (unapproval) => {
  const ret = []
  for (const data of unapproval) {
    // console.log(data)
    const tmp = {}
    tmp.id = data.workDate + data.staffId
    tmp.dateValue = data.workDate
    tmp.week = getWeekday(data.workDate)
    tmp.staffId = data.staffId
    tmp.staffName = data.staffName.replace('　', '').replace(' ', '')
    tmp.plannedStartDt = data.plannedWorkStartTime ? getTimeString(data.plannedWorkStartTime) : null
    tmp.plannedEndDt = data.plannedWorkEndTime ? getTimeString(data.plannedWorkEndTime) : null
    tmp.startDt = data.resultWorkStartTime ? getTimeString(data.resultWorkStartTime) : null
    tmp.endDt = data.resultWorkStartTime ? getTimeString(data.resultWorkEndTime) : null
    tmp.workHours = data.resultWorkTotal
    tmp.breakHours = data.resultWorkTotalRestTime
    tmp.overTime = data.resultWorkTotalOverTime
    tmp.nightTime = data.resultWorkTotalMidnight
    tmp.overtimeReason = ''
    tmp.approved = '未承認'
    tmp.approvedDate = ''
    tmp.approvedStaff = ''
    tmp.labors = data.labors?.length > 0 ? data.labors : null
    tmp.holiday = data.holiday
    ret.push(tmp)
  }
  return ret
}

export const buildOvertimeInfo = (overtimeInfo) => {
  const ret = []
  for (const data of overtimeInfo) {
    // console.log(data)
    const tmp = {}
    tmp.id = data.workDate + data.staffId
    tmp.dateValue = data.workDate
    tmp.week = getWeekday(data.workDate)
    tmp.staffId = data.staffId
    tmp.staffName = data.staffName
    // tmp.startDt = data.resultWorkStartTime ? getTimeString(data.resultWorkStartTime) : null
    // tmp.endDt = data.resultWorkStartTime ? getTimeString(data.resultWorkEndTime) : null
    tmp.foreseeType = getForeseeTypeString(data.foreseeType)
    tmp.causeType = getCauseTypeString(data.causeType)
    tmp.hours = data.hours
    tmp.explanation = data.explanation
    ret.push(tmp)
  }
  return ret
}

export const buildOvertimeAlert = (overtimeAlert) => {
  const ret = []
  for (const data of overtimeAlert) {
    // console.log(data)
    const tmp = {}
    tmp.id = data.staffId
    tmp.belong = data.belong
    tmp.staffId = data.staffId
    tmp.staffName = data.staffName
    tmp.approvedRate = `${data.approvedCnt}/${data.resultCnt}`
    tmp.workCnt = data.workCnt
    tmp.publicCnt = data.publicCnt
    tmp.recommendationPublc = data.recommendationPublic
    tmp.salaryCnt = data.salaryCnt
    tmp.daikyuCnt = data.daikyuCnt
    tmp.workTime = data.workTime
    tmp.overTime = data.overTime
    tmp.nightTime = data.nightTime
    tmp.zanOverTime = data.zanOverTime
    tmp.zanNightTime = data.zanNightTime
    tmp.aveTime = data.aveTime

    ret.push(tmp)
  }
  return ret
}

const laborTemplate = {
  dateValue: '',
  week: '',
  plannedStartDt: '',
  plannedEndDt: '',
  startDt: '',
  endDt: '',
  sumHour: '',
  breakHours: '',
  holiday: false
}
const getTime = (labors, fn) => {
  return labors.length > 0 && labors.reduce(fn)
}

const getCriteria = (year, month, day) => {
  const nightStart = DateTime.fromObject({
    year: year,
    month: month,
    day: day,
    hour: 22
  })

  const nightEnd = DateTime.fromObject({
    year: year,
    month: month,
    day: day,
    hour: 5
  }).plus({ day: 1 })

  return { nightStart, nightEnd }
}

const getBreakHoursInNightShift = (breakOpes, workDt) => {
  const ret = breakOpes.reduce((acc, cur) => {
    const [year, month, day] = workDt.split('-')
    const { nightStart, nightEnd } = getCriteria(year, month, day)
    const startTime = DateTime.fromFormat(cur.startDt, 'yyyy-MM-dd HH:mm:ss.S')
    const endTime = DateTime.fromFormat(cur.endDt, 'yyyy-MM-dd HH:mm:ss.S')
    if ((nightStart <= startTime && startTime <= nightEnd) || (nightStart <= endTime && endTime <= nightEnd)) {
      const calcStart = startTime > nightStart ? startTime : nightStart
      const calcEnd = endTime > nightEnd ? nightEnd : endTime
      return (calcEnd - calcStart) / 3600 / 1000
    } else {
      return acc
    }
  }, 0)
  return ret
}

export const calcNightHours = (start, end, dt) => {
  const [year, month, day] = dt.split('-')
  const nightStart = DateTime.fromObject({
    year: year,
    month: month,
    day: day,
    hour: 22
  })

  const nightEnd = DateTime.fromObject({
    year: year,
    month: month,
    day: day,
    hour: 5
  }).plus({ day: 1 })

  const startTime = DateTime.fromFormat(start, 'yyyy-MM-dd HH:mm:ss.S')
  const endTime = DateTime.fromFormat(end, 'yyyy-MM-dd HH:mm:ss.S')

  let ret = 0
  if (nightStart < endTime) {
    const calcStart = startTime > nightStart ? startTime : nightStart
    const calcEnd = endTime > nightEnd ? nightEnd : endTime
    ret = (calcEnd - calcStart) / 3600 / 1000
  }

  return ret
}

const getMin = (acc, cur) => acc.startIndex < cur.startIndex ? acc : cur
const getMax = (acc, cur) => acc.endIndex > cur.endIndex ? acc : cur
const getTimeString = t => DateTime.fromFormat(t, 'yyyy-MM-dd HH:mm:ss.S').toLocaleString(DateTime.TIME_24_SIMPLE)
const getDateTimeString = t => DateTime.fromFormat(t, 'yyyy-MM-dd HH:mm:ss').toFormat('yy/MM/dd HH:mm')
const getWeekday = t => DateTime.fromFormat(t, 'yyyy-MM-dd').setLocale('ja').toFormat('EEE')
// function compare (a, b) {
//   // console.log(a, b)
//   if (a.startIndex < b.startIndex) {
//     return -1
//   }
//   if (a.startIndex > b.startIndex) {
//     return 1
//   }
//   return 0
// }
export const icon = (classes, index, width, icon) => {
  return (
    <TableCell
      color='blue'
      key={index}
      width={width}
      align='center'
      className={classes.tableCell}
    >
      <IconContext.Provider value={{ color: '#d70035' }}>
        <FaStackOverflow />
      </IconContext.Provider>
    </TableCell>
  )
}

const WeekOfDay = styled.label`
  margin-left: 5px;
  color: ${props => props.week === '土' ? 'blue' : props.week === '日' ? 'red' : props.holiday ? 'red' : 'black'};
`

export const cell = (classes, index, width, row, key) => {
  return (
    <TableCell
      color='blue'
      key={index}
      width={width}
      align={isNaN(row[key]) ? 'left' : 'right'}
      className={classes.tableCell}
    >
      {key === 'week'
        ? <WeekOfDay week={row[key]} holiday={row.holiday}>{row[key]}</WeekOfDay>
        : <p>{row[key]}</p>}

    </TableCell>
  )
}

export const cell2 = (classes, index, header, row, key) => {
  return (
    <TableCell
      color='blue'
      key={index}
      width={header.width}
      align={header.numeric == null ? 'center' : header.numeric ? 'right' : 'left'}
      className={classes.tableCell}
    >
      {key === 'week'
        ? <WeekOfDay week={row[key]} holiday={row.holiday}>{row[key]}</WeekOfDay>
        : <p>{row[key]}</p>}

    </TableCell>
  )
}

export const cell3 = (classes, index, header, row, key) => {
  const alertClassCss = { color: 'red', fontWeight: 'bold' }

  return (
    <TableCell
      color='blue'
      key={index}
      width={header.width}
      align={header.numeric == null ? 'center' : header.numeric ? 'right' : 'left'}
      className={classes.tableCell}
    >
      {
        // 残業残工数、深夜残光数が0未満、または、平均工数が9.5以上の場合はアラート（文字色赤）
        (['zanOverTime', 'zanNightTime'].includes(key) && Number(row[key]) && Number(row[key]) < 0) ||
        (key === 'aveTime' && Number(row[key]) && Number(row[key]) > 9.5)
          ? <p style={alertClassCss}>{row[key]}</p>
          : <p>{row[key]}</p>
      }

    </TableCell>
  )
}
const getForeseeTypeString = (code) => {
  return code === 1 ? '予見型'
    : code === 2 ? '突発型'
      : null
}

const getCauseTypeString = (code) => {
  return code === 1 ? '顧客起因'
    : code === 2 ? 'スタッフ起因'
      : code === 3 ? '社員起因'
        : null
}

export const WithOverTimeToolTip = ({ children, overtimeReason, classes }) => {
  const foreseeType = (overtimeReason && overtimeReason.length > 0) &&
    getForeseeTypeString(overtimeReason[0].foreseeType)
  const causeType = (overtimeReason && overtimeReason.length > 0) &&
    getCauseTypeString(overtimeReason[0].causeType)
  const explanation = (overtimeReason && overtimeReason.length > 0) &&
    overtimeReason[0].explanation
  const hours = (overtimeReason && overtimeReason.length > 0) &&
    overtimeReason[0].hours
  return (

    <Tooltip
      style={{ color: 'blue', fontWeight: 'bold' }}
      placement='top' classes={{ tooltip: classes.customTooltip }}
      title={<span><p>予測: {foreseeType}</p><p>起因: {causeType}</p><p>予定時間: {hours}h</p><p>理由: {explanation}</p></span>}
    >
      {children}
    </Tooltip>
  )
}

export const overtimeReasonExists = (overtimeInfo, staffId, dateValue) => {
  const ret = dateValue != null
    ? overtimeInfo && overtimeInfo.filter(o => o.staffId === staffId && o.workDate === dateValue)
    : overtimeInfo && overtimeInfo.filter(o => o.staffId === staffId)
  return ret?.length > 0
}

export const getOvertimeReason = (overtimeInfo, staffId, dateValue) => {
  const ret = dateValue != null
    ? overtimeInfo && overtimeInfo.filter(o => o.staffId === staffId && o.workDate === dateValue)
    : overtimeInfo && overtimeInfo.filter(o => o.staffId === staffId)
  return ret || null
}

function descendingComparator (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
