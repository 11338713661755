/* eslint-env browser */
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FiDatabase } from 'react-icons/fi'
import { GetApi, exportErrorLog, forceLogout } from './Common/ApiAxios'
import logo from '../image/svg/colorLogo.svg'
import menuButton from '../image/menu-btn-1.png'
import wbIcon from '../image/menu-icon1.png'
import laborIcon from '../image/menu-icon2.png'
import quanIcon from '../image/menu-icon3.png'
import wbIcon2 from '../image/menu-icon1-2.png'
import laborIcon2 from '../image/menu-icon2-2.png'
import quanIcon2 from '../image/menu-icon3-2.png'
import masterIcon from '../image/menu-icon4.png'
import masterIcon2 from '../image/menu-icon4-2.png'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Menu, MenuItem, ListItemText, Button as MuiButton } from '@material-ui/core'

const StyledMuiButton = withStyles({
  root: {
    marginRight: 16,
    backgroundColor: '#cccccc',
    '&:hover': {
      backgroundColor: '#555555',
      color: '#ffffff'
    }
  },
  label: {
    display: 'flex',
    justifyContent: 'start'
  }
})(({ style, anchorEl, ...props }) => {
  return (
    <MuiButton
      style={{
        ...style,
        backgroundColor: anchorEl && '#649CDF',
        color: anchorEl && '#ffffff',
        width: 168,
        height: 56,
        borderColor: '#707070'
      }}
      {...props}
    />
  )
})
const StyledMenu = withStyles({
  paper: {
    overflowY: 'overlay',
    border: '1px solid #d3d4d5',
    margin: 0,
    '& .MuiList-padding': {
      padding: 0
    }
  }
})(props => (
  <Menu
    getContentAnchorEl={null}
    autoFocus={false}
    style={{ padding: 0 }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(theme => ({
  root: {
    width: 168,
    borderBottom: '#707070',
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: '#333333'
    },
    '&:hover': {
      backgroundColor: '#555555',
      color: theme.palette.common.white,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    },
    '&:focus': {
      backgroundColor: '#649CDF',
      color: theme.palette.common.white,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem)

const MenuNav = styled.nav`
  display: none;
  animation-name: menu;
  animation-duration: .5s;
  animation-timing-function: ease;
  position: absolute;
  width: 100vw;
  height: 80px;
  background: #ddd;
  top: 0;
  z-index: 1300;

  @keyframes menu {
    from {
      opacity: 0;
      transform: translateX(0);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`
const NormalLogoImg = styled.img`
  width: 130px;
  position: absolute;
  top: 20px;
  left: 60px;
`
const MenuImg = styled.img`
  cursor: pointer;
    margin: 0;
    width: 30px;
    position: absolute;
    top: 15px;
    left: 15px;
`
const MenusUl = styled.ul`
  display: flex;
  margin: 8px 0 0 235px;
  width: calc( 100vw - 250px);
  overflow-x: auto;
  height: 72px;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`
const LiImg = styled.span`
  width: 24px;
  height: 24px;
  background-size: 23px;
  background-image: url('${props => props.img}');
  background-repeat: no-repeat;
  background-position: center;
`
const NavButtom = styled.div`
  display: ${props => props.showFlg};
  width: 100vw;
  height: 100vh;
  top: 0;
  position: absolute;
  background: #ddd;
  opacity: 0.1;
`

const StyledTextLabel = styled.div`
  margin: 8px;
`

// ヘッドメニューリンクの作成
const NavigatorItem = ({ serviceMenuId, serviceMenuName, manageMenuList, onClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = useCallback(event => setAnchorEl(event.currentTarget), [setAnchorEl])
  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl])
  const wrappedOnClick = useCallback(e => {
    handleClose()
    onClick(e)
  }, [handleClose, onClick])
  let imgSrc = ''
  let reImgSrc = ''
  switch (serviceMenuId) {
    case '04':
      imgSrc = masterIcon
      reImgSrc = masterIcon2
      break
    case '10':
      imgSrc = wbIcon
      reImgSrc = wbIcon2
      break
    case '11':
      imgSrc = laborIcon
      reImgSrc = laborIcon2
      break
    case '12':
      imgSrc = quanIcon
      reImgSrc = quanIcon2
      break
    case '13':
      imgSrc = null
      reImgSrc = null
      break
    default:
      return <></>
  }
  return (
    <div style={{ height: 60 }}>
      <StyledMuiButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='contained'
        anchorEl={anchorEl}
        onClick={handleClick}
      >
        {
          imgSrc && reImgSrc
            ? <LiImg img={anchorEl ? reImgSrc : imgSrc} />
            : <FiDatabase size={24} />
        }

        <StyledTextLabel>{serviceMenuName}</StyledTextLabel>
      </StyledMuiButton>
      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div>
          <SubNavi manageMenuList={manageMenuList} onClick={wrappedOnClick} open={Boolean(anchorEl)} />
        </div>
      </StyledMenu>
    </div>
  )
}

NavigatorItem.propTypes = {
  serviceMenuId: PropTypes.string,
  serviceMenuName: PropTypes.string,
  manageMenuList: PropTypes.array,
  onClick: PropTypes.func
}

const SubNavi = ({ open, manageMenuList, onClick, ...props }) => {
  return (
    <>
      {open && manageMenuList.map((manageMenu, index) => {
        let linkTo = ''
        switch (manageMenu.manageMenuId) {
          case '0401':
            linkTo = '/master/staff'
            break
          case '0402':
            linkTo = '/master/company'
            break
          case '0406':
            linkTo = '/master/grant'
            break
          case '0411':
            linkTo = '/master/skill'
            break
          case '0412':
            linkTo = '/master/role'
            break
          case '0413':
            linkTo = '/master/warehouse'
            break
          case '0414':
            linkTo = '/master/floor'
            break
          case '0415':
            linkTo = '/master/zone'
            break
          case '0416':
            linkTo = '/master/units'
            break
          case '0417':
            linkTo = '/master/quantity'
            break
          case '0418':
            linkTo = '/master/category'
            break
          case '0419':
            linkTo = '/master/operation'
            break
          case '0420':
            linkTo = '/master/productivity'
            break
          case '0421':
            linkTo = '/master/timeFrame'
            break
          case '0422':
            linkTo = '/master/credentialsApikey'
            break
          case '1001':
            linkTo = '/whiteboard/whiteboard'
            break
          case '1002':
            linkTo = '/whiteboard/mapping'
            break
          case '1099':
            //linkTo = '/whiteboard/positioning'
            linkTo = '/whiteboard/positioning/board'
            break
          case '1101':
            linkTo = '/labor/planned'
            break
          case '1102':
            linkTo = '/labor/result'
            break
          case '1103':
            linkTo = '/labor/list'
            break
          case '1104':
            linkTo = '/labor/individual'
            break
          case '1105':
            linkTo = '/labor/approval'
            break
          case '1201':
            linkTo = '/quantity/quantityData'
            break
          case '1202':
            linkTo = '/quantity/productivityData'
            break
          case '1203':
            linkTo = '/quantity/operationData'
            break
          case '1301':
            linkTo = '/data-management/csv-export'
            break
          case '1302':
            linkTo = '/data-management/analyze'
            break
          default:
            return <></>
        }
        return (
          <Link key={index} style={{ textDecoration: 'none' }} to={linkTo} data-service-menu-id={manageMenu.manageMenuId} onClick={onClick}>
            <StyledMenuItem>
              <ListItemText primary={manageMenu.manageMenuName} />
            </StyledMenuItem>
          </Link>
        )
      })}
    </>
  )
}

SubNavi.propTypes = {
  onClick: PropTypes.func,
  manageMenuList: PropTypes.array,
  open: PropTypes.bool
}

const Navigator = (props) => {
  const [serviceList, setServiceList] = useState([])
  const [selectedServiceId, setSelectedServiceId] = useState('')
  const loginUserInfo = useSelector(state => state.loginUserInfo)

  // メニューリスト
  async function getMenuList (cleanedUp) {
    const apiUrl = '/api/menuList'
    const grantNameId = loginUserInfo.grantNameId
    const data = { grantNameId }
    const result = await GetApi(apiUrl, data)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
        forceLogout()
      } else {
        alert('情報取得に失敗しました。')
      }
      window.location.href = '/login'
    } else {
      console.log(result.data)
      setServiceList(result.data)
    }
  }

  useEffect(() => {
    if (loginUserInfo.grantNameId) {
      getMenuList()
    }
  // ログインユーザー情報の変更検知時のみ実行する
  // eslint-disable-next-line
  }, [loginUserInfo])

  // サービス選択イベント
  const selectServiceMenu = e => {
    showMenu()
    setSelectedServiceId({ selectedServiceId: e.currentTarget.dataset.serviceMenuId })
  }

  const showMenu = () => {
    document.querySelectorAll('input[name="menuLi"]').forEach(item => {
      item.checked = false
    })
    props.setShowFlg(props.showFlg === 'none' ? 'block' : 'none')
  }

  return (
    <>
      <MenuNav className='menu' style={{ display: props.showFlg }}>
        <MenuImg src={menuButton} alt='menuButton' onClick={showMenu} />
        <Link to='/' className='logo'>
          <NormalLogoImg src={logo} alt='Logo' />
        </Link>
        <MenusUl>
          {serviceList.map(data =>
            <NavigatorItem
              key={data.serviceMenuId}
              onClick={selectServiceMenu}
              isSelected={selectedServiceId === data.serviceMenuId ? 'selected' : ''}
              {...data}
            />)}
        </MenusUl>
      </MenuNav>
      <NavButtom onClick={showMenu} showFlg={props.showFlg} />
    </>
  )
}

Navigator.propTypes = {
  isPlanned: PropTypes.bool,
  canWrite: PropTypes.bool,
  staffId: PropTypes.string,
  onClick: PropTypes.func,
  showFlg: PropTypes.string,
  setShowFlg: PropTypes.func
}

export default Navigator
