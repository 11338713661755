/* eslint-env browser */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import RowLeft from './RowLeft'
import RowRight from './RowRight'

const RowLi = styled.li`
  display: flex;
`

const getTimeIndex = (dt, workDate) => {
  // dt format: 2021-06-04 08:00:00.0
  const [d, t] = dt.split(' ')
  const [h, m, s] = t.split(':')
  const idx = d === workDate
    ? (+h * 4) + (+m / 15)
    : (+h * 4) + (+m / 15) + (24 * 4) // 日跨ぎ
  return idx
}

const Row = (props) => {
  const { individualMonthly } = useSelector(state => state)
  const [approved, setApproved] = useState(false)
  const dateItems = props.dateItems
  const { planned } = props
  // console.log(props.planned)
  useEffect(() => {
    if (individualMonthly) {
      setApproved(individualMonthly.laborApproval?.filter(l => l.approvedDate === dateItems.dateValue).length > 0)
    }
  }, [])
  return (
    <RowLi>
      <RowLeft approved={approved} {...props} />
      <RowRight
        approved={approved}
        plannedStartIndex={planned && getTimeIndex(planned.startDt, dateItems.dateValue)}
        plannedEndIndex={planned && getTimeIndex(planned.endDt, dateItems.dateValue)}
        {...props}
      />
    </RowLi>
  )
}

export default Row
