/* eslint-env browser */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const WrapDiv = styled.div`
  min-height:50px;
  max-height:50px;
  flex-shrink: 0;
  display: flex;
  margin-bottom: 3px;
  position:sticky;
  left: 0px;
  z-index:1;
`
const InfoDiv = styled.div`
  width: 150px;
  line-height: 48px;
  background: #fff;
  font-size: 14px;
  display: flex;
`
const Hours = styled.label`
  line-height:50px;
  width:50px;
  text-align:right;
  padding-right:3px;
  font-size: 12px;
  background: #eee;
  text-align: center;
`
const WeekLabel = styled.label`
  margin-left: 5px;
  color: ${props => props.week === '土' ? 'blue' : props.week === '日' ? 'red' : props.holiday ? 'red' : 'black'};
`
const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  display: ${props => props.allUpdateOpen ? 'block' : 'none'};
  position: absolute;
  top: 15px;
`

const Approved = () => <span style={{ position: 'absolute', top: 10, marginLeft: 20, color: 'red', fontSize: '0.9em' }}>承認済</span>

const RowLeft = ({ dateItems, allUpdateOpen, laborToggle, temperature, approved = false }) => {
  const [isHoliday, setIsHoliday] = useState(false)
  const [startTime, setStartTime] = useState(' - ')
  const [endTime, setEndTime] = useState(' - ')

  const Week = () => {
    return (
      <WeekLabel week={dateItems.week} holiday={dateItems.holiday}>
        {dateItems.week}
      </WeekLabel>
    )
  }

  useEffect(() => {
    if (dateItems.labors && dateItems.labors.length > 0) {
      setIsHoliday(false)
      // 開始時間、終了時間
      dateItems.labors.forEach(labor => {
        if (labor.operationName === '休日' || labor.operationName === '休暇') {
          setIsHoliday(true)
        }
      })
      const startIndex = dateItems.labors.reduce((tmp, labor) => {
        if (tmp === 0 || tmp > labor.startIndex) {
          return labor.startIndex
        } else {
          return tmp
        }
      }, 0)
      const endIndex = dateItems.labors.reduce((tmp, labor) => {
        if (tmp === 0 || tmp < labor.endIndex) {
          return labor.endIndex
        } else {
          return tmp
        }
      }, 0)
      setStartTime(('00' + Math.floor(startIndex * 15 / 60)).slice(-2) + ':' + ('00' + (startIndex * 15 % 60)).slice(-2))
      setEndTime(('00' + Math.floor(endIndex * 15 / 60)).slice(-2) + ':' + ('00' + (endIndex * 15 % 60)).slice(-2))
    } else {
      setIsHoliday(false)
      setStartTime(' - ')
      setEndTime(' - ')
    }
  }, [dateItems])

  const startEndTime = (startTime !== ' - ' && endTime !== ' - ') ? `${startTime} - ${endTime}` : ' - '
  const bodyTemp = temperature && temperature.length > 0 ? temperature[0].bodyTemperature + '℃' : ''
  return (
    <WrapDiv>
      <CheckBox className='allUpdateCheckBox' allUpdateOpen={allUpdateOpen} data-date={dateItems.dateValue} />
      <InfoDiv style={dateItems.today
        ? { backgroundColor: '#f5f53d' }
        : (laborToggle !== 'planned' && approved) ? { backgroundColor: '#FFF9C4', color: '#333' } : {}}
      >
        <div style={{ display: 'flex', flexDirection: 'column', width: 60 }}>

          <div style={{ width: 60, marginLeft: 16, height: 25, display: 'flex', alignItems: 'center' }}>
            <label>{dateItems.dateView}</label>
            <Week />
          </div>
          {laborToggle !== 'planned' && approved && <Approved />}
        </div>
        <div style={{ width: 90, paddingLeft: 12 }}>
          {isHoliday ? (
            <div style={{ height: 50, lineHeight: '49px' }}>休日</div>
          ) : (
            <>
              <div style={{ height: 25, lineHeight: '28px', fontSize: '0.9em' }}>{startEndTime}</div>
              <div style={{ height: 25, lineHeight: '28px', fontSize: '0.9em' }}>{bodyTemp}</div>
            </>
          )}
        </div>
      </InfoDiv>
      <Hours>{dateItems.sumHour}</Hours>
    </WrapDiv>
  )
}

RowLeft.propTypes = {
  dateItems: PropTypes.object,
  allUpdateOpen: PropTypes.bool,
  laborToggle: PropTypes.string,
  approved: PropTypes.bool
}

export default RowLeft
