import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GetApi } from '../../Common/ApiAxios'
import Search from '../../Common/Search'
import MasterListView from '../../Common/Molecules/MasterListView'
import MasterWrap from '../../Common/Wrap/MasterWrap'
import StaffDetails from './StaffDetails'
import '../../../css/Master/staff.scss'
import FUNC from '../../Common/FunctionEnum'
import { useAlert } from 'react-alert'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../Common/ErrorHandling'
import Input from '../../Common/Atoms/Input'

export const DefaultUrl = '/api/master/staff'

// メインコンポーネント
const StaffMaster = () => {
  const staffIdRef = useRef()
  const staffNameRef = useRef()
  const [dataList, setDataList] = useState([])
  const [detail, setDetail] = useState({})
  const [selectedId, setSelectedId] = useState()
  const [searchOption, setSearchOption] = useState({ staffId: '', staffName: '' })
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const { companyId, warehouseId, floorId, zoneId } = commonSearch
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.STAFF)
  const alert = useAlert()
  const dispatch = useDispatch()
  const [companyList, setCompanyList] = useState([]) // ゾーンまでそろっている会社情報取得
  const [pagination, setPagination] = useState({})

  const selectItem = async (id) => {
    dispatch(SetLoadSpinner(true))
    setSelectedId(id)
    const result = await GetApi(`${DefaultUrl}/${id}`)
    dispatch(SetLoadSpinner(false))
    ErrorHandling(result, alert) || setDetail(result.data)
  }

  // 新規登録用初期化処理
  const createNew = (e) => {
    setDetail({})
    setSelectedId(null)
  }

  // 検索APIの実行
  const onSearch = async (createId, page) => {
    dispatch(SetLoadSpinner(true))
    setSearchOption(
      {
        staffId: staffIdRef.current.value,
        staffName: staffNameRef.current.value
      }
    )
    const data = {
      companyId: companyId,
      warehouseId: warehouseId,
      floorId: floorId,
      zoneId: zoneId,
      staffId: grants.readGrantDivision === 3 ? loginUserInfo.staffId : staffIdRef.current.value,
      staffName: staffNameRef.current.value,
      page: page
    }
    const result = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else {
      const resultList = result.data.responseList.map(item => {
        return {
          id: item.staffId,
          name: item.staffName
        }
      })
      setDataList(resultList)
      setPagination(result.data)
      const isSelected = resultList.find(item => item.id === (createId || selectedId))
      if (!isSelected) {
        setSelectedId(null)
        setDetail({})
      }
    }
  }

  // 会社情報一覧
  const onCompanyInfoSearch = async () => {
    dispatch(SetLoadSpinner(true))
    const data = {
      notBlankStatus: 1 // 会社-倉庫が欠損なしのレコード
    }
    const result = await GetApi('/api/companyInfo', data)
    dispatch(SetLoadSpinner(false))
    if (ErrorHandling(result, alert)) return null
    if (result.data.length === 0) {
      alert.error('必要データが揃っていません', { onClose: () => { window.location.href = '/' } })
    }
    setCompanyList(result.data)
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, 1)
    }
  }

  const pageSelectFunc = (pageId) => {
    onSearch(null, pageId)
  }

  useEffect(() => {
    if (staffIdRef.current && searchOption) {
      staffIdRef.current.value = searchOption.staffId
      staffNameRef.current.value = searchOption.staffName
    }
  // データが変更された場合のみ実行
  // eslint-disable-next-line
  }, [dataList, detail])

  useEffect(() => {
    if (loginUserInfo.companyId) {
      onCompanyInfoSearch()
    }
  // ログインユーザー情報が変更された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo])

  useEffect(() => {
    document.title = 'スタッフマスタ | Core First'
  }, [])

  if (!grants) return null
  return (
    <MasterWrap>
      <Search
        title='スタッフマスタ'
        onClick={() => onSearch(null, 1)}
        showWarehouses showFloors showZones
        addAllWarehouse
        addAllFloor
        addAllZone
        inSubCompany inSubChildren
        showButton
        grants={grants}
      >
        <li>
          <label>ID</label>
          <Input type='text' name='staffId' inputRef={staffIdRef} onKeyDown={(e) => onKeyDown(e)} disabled={grants.readGrantDivision === 3} />
        </li>
        <li>
          <label>名称</label>
          <Input type='text' name='staffName' inputRef={staffNameRef} onKeyDown={(e) => onKeyDown(e)} disabled={grants.readGrantDivision === 3} />
        </li>
      </Search>
      <main>
        <MasterListView
          dataList={dataList}
          selectedId={selectedId}
          selectFunction={(id) => selectItem(id)}
          createFunction={() => createNew()}
          canWrite={grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1}
          pageSelectFunc={pageSelectFunc}
          pagination={pagination}
          isIdView
        />
        <StaffDetails
          companyList={companyList}
          onSearch={onSearch}
          setSelectedId={setSelectedId}
          staffDetail={detail}
          setStaffDetail={setDetail}
          grants={grants}
        />
      </main>
    </MasterWrap>
  )
}

export default StaffMaster
