import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import TabSelector from '../../Common/Atoms/TabSelector'
import {
  cell3,
  stableSort,
  getComparator
} from './helper'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: '1 1 100%'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

const searchEmpList = [
  { value: 0, name: '全データ' },
  { value: 1, name: '社員' },
  { value: 2, name: '社員以外' }
]

const TableToolbar = (props) => {
  const classes = useToolbarStyles()
  // const { from, to } = props
  const { from, to, searchEmpCond, setSearchEmpCond } = props
  // const [searchEmpCond, setSearchEmpCond] = useState(0)

  return (
    <Toolbar
      className={classes.root}
    >
      <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
        {from} ～ {to}
      </Typography>
      <div style={{ padding: '10px 8px 0 0' }}>
        <TabSelector
          selectValue={searchEmpCond}
          setSelectValue={setSearchEmpCond}
          selectedBackColor='#303F9F'
          title=''
          tabsName='searchEmp'
          dataList={searchEmpList}
        />

      </div>
    </Toolbar>
  )
}

TableToolbar.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  searchEmpCond: PropTypes.number,
  setSearchEmpCond: PropTypes.func
}

// TODO 共通化
/**
 * Header
 * @param {*} props
 */
function OvertimeAlertTableHead (props) {
  const {
    headCells,
    order,
    orderBy,
    onRequestSort
  } = props
  const classes = useToolbarStyles()

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }
  const createHeadCell = (headCell) => {
    return (
      <div>
        {headCell.label.split(' ').map((h, idx) => <p key={idx}>{h}<br /></p>)}
      </div>
    )
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'default'}
            className={classes.tableCell}
            style={{ fontWeight: 'bold', align: 'center' }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {createHeadCell(headCell)}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}

      </TableRow>
    </TableHead>
  )
}

OvertimeAlertTableHead.propTypes = {
  headCells: PropTypes.array,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableCell: {
    paddingRight: 5
  }
}))

/**
 * テーブル本体
 */
export default function OvertimeAlertTable (props) {
  // const alert = useAlert()
  const { headCells, rows, from, to } = props
  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('dataValue')
  const [searchEmpCond, setSearchEmpCond] = useState(0)

  const filterRows = rows => {
    if (searchEmpCond === 0) return rows

    return searchEmpCond === 1
      ? rows.filter(r => r.staffId.length === 7 && r.staffId.substring(0, 1) === '9')
      : rows.filter(r => r.staffId.length !== 7 || r.staffId.substring(0, 1) !== '9')
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar
          // numSelected={selected.length}
          title={props.title}
          from={from}
          to={to}
          searchEmpCond={searchEmpCond}
          setSearchEmpCond={setSearchEmpCond}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size='small'
            aria-label='table'
          >
            <OvertimeAlertTableHead
              classes={classes}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {/* ソート追加 */}
              {stableSort(filterRows(rows), getComparator(order, orderBy))
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      role='row'
                    >
                      {
                        row && Object.keys(row).map((key, _index) => {
                          const dataHeader = headCells.filter(h => h.id === key)
                          if (dataHeader.length > 0) {
                            return (
                              <React.Fragment key={_index}>
                                {cell3(classes, _index, dataHeader[0], row, key)}
                              </React.Fragment>
                            )
                          }
                        })
                      }

                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

OvertimeAlertTable.propTypes = {
  title: PropTypes.string,
  headCells: PropTypes.array,
  rows: PropTypes.array.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
}
