import PropTypes from 'prop-types'
import { useCallback, useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { restfulApiConfig } from '../Common/Config'

// ミリ秒をhh:mm:ssに変換
export function msToTime (duration) {
  /*
    1minute = 60000ms
    1hour = 60minutes = 3600000ms
   */
  const hour = Math.floor(duration / 3600000)
  const minute = Math.floor((duration - 3600000 * hour) / 60000)

  const hh = ('00' + hour).slice(-2)
  const mm = ('00' + minute).slice(-2)
  const ms = ('00000' + (duration % 60000)).slice(-5)

  const time = `${hh}:${mm}:${ms.slice(0, 2)}`

  return time
}

// react-hook-form setValueの自動化関数
export const setValues = (obj, setValue, path = '') => {
  if (typeof setValue !== 'function') return
  if (!obj) return
  Object.keys(obj).reduce((_, cur) => {
    switch (typeof obj[cur]) {
      case 'object':
        setValues(obj[cur], setValue, `${path && path + '.'}${cur}`)
        break
      default :
        setValue(`${path && path + '.'}${cur}`, obj[cur], true)
    }
    return true
  }, [])
}
setValues.propTypes = {
  obj: PropTypes.object, // setValueに利用するオブジェクト
  setValue: PropTypes.func.isRequired, // react-hook-form の setValue
  path: PropTypes.string // 特定の階層以降をsetValueする場合のみ指定
}

// 日付文字列を日付型に変換
export const toDateByString = (dateString) => {
  if (!dateString) return null
  return new Date(dateString.substring(0, 4), dateString.substring(4, 6) - 1, dateString.substring(6, 8))
}

// ID自動生成
export function useIdGenerator () {
  let id = Math.random().toString()
  while (document.getElementById(id) != null) {
    id = Math.random().toString()
  }
  return id
}

// 郵便番号から住所を取得
export function useYubinbango (postalCode) {
  const [addressObject, setAddressObject] = useState(null)

  const yubinbangoCore = useCallback((postalCode) => {
    const postalCodeLength = `${postalCode}`.replace(/-/g, '').length
    // コンストラクタ呼び出しのみで良いため、lintルール排除
    // eslint-disable-next-line
    window?.YubinBango?.Core && 8 > postalCodeLength && postalCodeLength > 6 
      ? new window.YubinBango.Core(postalCode, d => { setAddressObject(() => d) })
      : null
  }, [setAddressObject])

  useEffect(() => {
    yubinbangoCore(postalCode)
  // postalCode 変更検知時のみ動作させる
  // eslint-disable-next-line
  }, [postalCode])
  return ({ ...addressObject })
}
useYubinbango.propTypes = {
  postalCode: PropTypes.string // 郵便番号文字列(ハイフン有無問わない)
}

// 権限判定 権限区分、対象staffId、 対象warehouseId、 loginUser情報で判定
export const grantDecision = (division, grantId, loginUserInfo, warehouseId, staffId) => {
  const loginWarehouseId = loginUserInfo.warehouseId
  const loginId = loginUserInfo.staffId
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === grantId)

  if (!grants) return false
  if (division === 'write') {
    return grants.writeGrantDivision === 2 || (grants.writeGrantDivision === 1 && (!warehouseId || loginWarehouseId === warehouseId)) ||
      (grants.writeGrantDivision === 3 && loginId === staffId)
  } else if (division === 'read') {
    return grants.readGrantDivision === 2 || (grants.readGrantDivision === 1 && (!warehouseId || loginWarehouseId === warehouseId)) ||
      (grants.readGrantDivision === 3 && loginId === staffId)
  } else {
    return false
  }
}

// 日付フォーマット
const DATETIME_FROM_FORMAT = 'yyyy/MM/dd HH:mm:ss'
const DATETIME_TO_SHORT_FORMAT = 'MM/dd HH:mm'

export const toFormatShortDateTime = (dt) => DateTime.fromFormat(dt, DATETIME_FROM_FORMAT).toFormat(DATETIME_TO_SHORT_FORMAT)
export const getHoursDiff = (start, end) => DateTime.fromFormat(end, 'yyyy-MM-dd HH:mm:ss.S').diff(DateTime.fromFormat(start, 'yyyy-MM-dd HH:mm:ss.S'), 'hours').toObject()
export const getHour = (dt) => DateTime.fromFormat(dt, 'yyyy-MM-dd HH:mm:ss.S').toFormat('HH')
export const getMin = (dt) => DateTime.fromFormat(dt, 'yyyy-MM-dd HH:mm:ss.S').toFormat('mm')
export const getDate = (dt) => DateTime.fromFormat(dt, 'yyyy-MM-dd HH:mm:ss.S').toFormat('yyyy-MM-dd')

// Cookie設定
function generateExpireDate (duration) { // min
  const dt = new Date()
  dt.setMinutes(dt.getMinutes() + duration)
  return dt.toUTCString()
}
export function setAccessTokenCookie (token) {
  //const expireDuration = 10 // 10min
  const expireDuration = 60 * 24 // 1 dayy
  const expireDtDuration = 60 * 24 * 7 // min * hour * day (1week) -> refresh tokenに合わせる
  const expireDt = generateExpireDate(expireDuration)

  const tokenExpireDt = generateExpireDate(expireDtDuration)
  document.cookie = `accessToken=${token}; path=/;expires=${expireDt}`
  document.cookie = `accessTokenExpires=${expireDt}; path=/; expires=${tokenExpireDt}`
}

export function setRefreshTokenCookie (token) {
  const duration = 60 * 24 * 7 // min * hour * day (1week)
  const expireDt = generateExpireDate(duration)
  document.cookie = `refreshToken=${token}; path=/;expires=${expireDt}`
  document.cookie = `refreshTokenExpires=${expireDt}; path=/; expires=${expireDt}`
}

export function clearAuthCookie () {
  document.cookie = 'accessToken=;path=/; max-age=0;'
  document.cookie = 'refreshToken=;path=/; max-age=0;'
  document.cookie = 'accessTokenExpires=;path=/; max-age=0;'
  document.cookie = 'refreshTokenExpires=;path=/; max-age=0;'
}

/**
 * 指定されたmonth(yyyy-MM)の開始日と締日を返す
 * @param {*} month 対象月
 * @param {*} baseDay 開始日（締日の前日）
 * @param {*} isStartFromLastMonth 開始日を前月にするか当月にするか
 * @returns 開始日、締日
 */
export const getFirstAndLastDateFromYyyyMm = (month, baseDay, isStartFromLastMonth) => {
  const dt = DateTime.fromFormat(month, 'yyyy-MM')
  const baseDate = isStartFromLastMonth
    ? dt.minus({ months: 1 })
    : dt

  const firstDate = DateTime.local(baseDate.year, baseDate.month, Number(baseDay))
  const lastDate = firstDate.plus({ month: 1 }).minus({ day: 1 })
  // console.log('startDay', startDay)
  return [firstDate.toFormat('yyyy-MM-dd'), lastDate.toFormat('yyyy-MM-dd')]
}

export const getFirstAndLastDate = (month) => {
  const baseDay = restfulApiConfig.startDay
  const [first, last] = getFirstAndLastDateFromYyyyMm(month, baseDay, false)
  return [first, last]
}
