import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  mapMode: 0, // 選択中のページ(デフォルトはリアルモード)
  requestClearInterval: null, //周期処理のクリア要求
}

const PositioningBoardSlice = createSlice({
  name: 'positioningBoard',
  initialState,
  reducers: {

    // MAPモードセット
    SetMapMode: (state, action) => {
      //console.log('call SetMapMode');
      //console.log(action);
      const { mapMode } = action.payload;
      state.mapMode = mapMode;
    },
    SetRequestClearInterval: (state, action) => {
      //console.log('call SetRequestClearInterval');
      //console.log(action);
      const { requestClearInterval } = action.payload;
      state.requestClearInterval = requestClearInterval;
    },

    reset: (state, action) => {
      state.mapMode = 0;
      state.requestClearInterval = null;
    }
  }
})

export const CommonPositioningBoardStates = () => {
  return useSelector((state) => state.positioningBoardInfo)
}

export const {
  SetMapMode,
  SetRequestClearInterval,
  reset
} = PositioningBoardSlice.actions

export default PositioningBoardSlice.reducer
