/* eslint-env browser */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { PutApi, exportErrorLog, forceLogout } from '../../../../../Common/ApiAxios'
import AllUpdateRowRight from './AllUpdateRowRight'
import { useAlert } from 'react-alert'
import { getIndividualMonthlyLabors, LaborPredictionStates } from '../../../IndividualMonthlySlice'
import { SetLoadSpinner } from '../../../../../Common/Slice/LoadSpinnerSlice'

const BREAK = '休憩'
const COMMUTING = '通勤'
const HOLIDAY = '休日'
const VACATION = '休暇'

const AllUpdateLi = styled.li`
  display: flex;
  position: sticky;
  top: 33px;
  z-index: 4;
  background: #111;
  padding-bottom: 3px;
`
const LeftDiv = styled.div`
  min-height:50px;
  max-height:50px;
  flex-shrink: 0;
  display: flex;
  position:sticky;
  left: 0px;
  z-index:1;
`
const InfoDiv = styled.div`
  width: 150px;
  background: #34968d;
  color: white;
  display: flex;
`
const Hours = styled.label`
  line-height:50px;
  width:50px;
  text-align:right;
  padding-right:3px;
  font-size: 12px;
  text-align: center;
  background: #005f5f;
  color: white;
`
const UpdateButton = styled.button`
  display: block;
  margin: 2px auto;
  width: 40px;
  height: 23px;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.05em;
  background: #0036ff;
  border-radius: 5px;
  border: none;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  color: white;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  &:hover{
      background: #0026b3;
  }
`
const CheckBox = styled.input.attrs({ type: 'checkbox' })`
`

const AllUpdate = (props) => {
  const { staff, month, laborToggle, dateItems } = props
  const alert = useAlert()
  const dispatch = useDispatch()
  const [labors, setLabors] = useState([])
  const [sumHour, setSumHour] = useState('0.0')
  const [isHoliday, setIsHoliday] = useState(false)
  const [startTime, setStartTime] = useState(' - ')
  const [endTime, setEndTime] = useState(' - ')

  // const xxx = LaborPredictionStates()
  const laborPrediction = LaborPredictionStates().laborTemplate

  useEffect(() => {
    if (laborPrediction.length) {
      setLabors(laborPrediction)
    }
  }, [laborPrediction])

  const updateAllLabor = async (days) => {
    localStorage.setItem('processingFlg', true)
    dispatch(SetLoadSpinner(true))
    const apiUrl = `/api/labor/individual/monthly/${staff.staffId}/${month}/${laborToggle}`
    const data = {
      days: days,
      labors: labors
    }
    const result = await PutApi(apiUrl, data, true)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
        forceLogout()
      } else {
        alert.error('更新に失敗しました。')
      }
      localStorage.setItem('processingFlg', false)
      dispatch(SetLoadSpinner(false))
    } else {
      dispatch(getIndividualMonthlyLabors(staff, month, laborToggle))
      localStorage.setItem('processingFlg', false)
      alert.info('更新しました。')
    }
  }

  const changeAllCheckBox = (e) => {
    document.querySelectorAll('.allUpdateCheckBox').forEach(i => {
      i.checked = e.target.checked
    })
  }

  const onClickUpdateButton = () => {
    const days = []
    document.querySelectorAll('.allUpdateCheckBox').forEach((i, index) => {
      if (i.checked) {
        days.push(i.getAttribute('data-date'))
      }
    })
    if (days.length === 0) {
      alert.error('日付が選択されていません。')
      return null
    }
    if (labors.length === 0) {
      const noLaborsQuestion = window.confirm('工程が入力されていませんが、よろしいでしょうか。')
      if (noLaborsQuestion === false) {
        return null
      }
    }
    const updateQuestion = window.confirm('選択した日付の工程が上書きされますが、よろしいでしょうか')
    if (updateQuestion === false) return null
    updateAllLabor(days)
  }

  React.useEffect(() => {
    if (labors && labors.length > 0) {
      const calcHours = labors.reduce((tmp, labor) => {
        if (labor.operationName === BREAK || labor.operationName === COMMUTING || labor.operationName === HOLIDAY || labor.operationName === VACATION) {
          return tmp
        }
        return tmp + (labor.endIndex - labor.startIndex) * 0.25
      }, 0.0)
      if (calcHours % 1 === 0) {
        setSumHour(calcHours.toString() + '.0')
      } else {
        setSumHour(calcHours.toString())
      }
      // 開始時間、終了時間
      labors.forEach(labor => {
        if (labor.operationName === '休日' || labor.operationName === '休暇') {
          setIsHoliday(true)
        }
      })
      const startIndex = labors.reduce((tmp, labor) => {
        if (tmp === 0 || tmp > labor.startIndex) {
          return labor.startIndex
        } else {
          return tmp
        }
      }, 0)
      const endIndex = labors.reduce((tmp, labor) => {
        if (tmp === 0 || tmp < labor.endIndex) {
          return labor.endIndex
        } else {
          return tmp
        }
      }, 0)
      setStartTime(('00' + Math.floor(startIndex * 15 / 60)).slice(-2) + ':' + ('00' + (startIndex * 15 % 60)).slice(-2))
      setEndTime(('00' + Math.floor(endIndex * 15 / 60)).slice(-2) + ':' + ('00' + (endIndex * 15 % 60)).slice(-2))
    } else {
      setSumHour('0.0')
      setStartTime(' - ')
      setEndTime(' - ')
      setIsHoliday(false)
    }
    dispatch(SetLoadSpinner(false))
  }, [labors])

  return (
    <AllUpdateLi>
      <LeftDiv>
        <InfoDiv>
          <div style={{ width: 60, textAlign: 'center' }}>
            <UpdateButton onClick={() => onClickUpdateButton()}>反映</UpdateButton>
            <span>
              <CheckBox onChange={e => changeAllCheckBox(e)} />
              <label>ALL</label>
            </span>
          </div>
          <div style={{ width: 90, paddingLeft: 8 }}>
            {isHoliday ? (
              <div style={{ height: 50, lineHeight: '49px' }}>休日</div>
            ) : (
              <>
                <div style={{ height: 25, lineHeight: '28px', fontSize: '14px' }}>開始{startTime}</div>
                <div style={{ height: 25, lineHeight: '22px', fontSize: '14px' }}>終了{endTime}</div>
              </>
            )}
          </div>
        </InfoDiv>
        <Hours>{sumHour + 'H'}</Hours>
      </LeftDiv>
      <AllUpdateRowRight labors={labors} setLabors={setLabors} dateValue={dateItems.dateValue} {...props} />
    </AllUpdateLi>
  )
}

AllUpdate.propTypes = {
  staff: PropTypes.object,
  month: PropTypes.string,
  laborToggle: PropTypes.string,
  dateItems: PropTypes.object
}

export default AllUpdate
