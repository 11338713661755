import styled from 'styled-components'

const MasterDetailWrap = styled.div` 
  width: 74vw;
  margin: ${props => props.margin || '0 0 12px 0'};
  display: ${props => props.isView ? 'block' : 'none'};
  ul {
    width: 73vw;
    margin: ${props => props.ulMargin || '0'};
    position: ${props => props.position || 'relative'};
    >li {
      display: flex;
      height: 60px;
      >label {
        width: 10vw;
        min-width: 10vw;
        background: #CCC;
        display: block;
        text-align: center;
        line-height: 60px;
      }
      >div {
        display: flex;
        width: 63vw;
        background: #EEEEEE;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    border:none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`
export default MasterDetailWrap
