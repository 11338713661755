/* eslint-env browser */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import FUNC from '../../Common/FunctionEnum'
import GanttChart from './GanttChart/GanttChart'
import Menu from './Menu'
import SideMenu from './SideMenu'
import { getIndividualMonthlyLabors, SetDateRecords, getLaborApproval } from './IndividualMonthlySlice'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { SetCommonSearch } from '../../Common/Slice/SearchSlice'
import Summary from './Summary'
import { GetApi, exportErrorLog } from '../../Common/ApiAxios'
import { getFirstAndLastDate } from '../../Common/utils'
import { getPlannedPersonalAttendance } from '../LaborSlice'

const LaborDiv = styled.div`
  margin-left: 16px;
  position: relative;
`

const IndividualMonthly = () => {
  // const date = new Date()
  const dispatch = useDispatch()
  const { loginUserInfo, individualMonthly, commonSearch } = useSelector(state => state)
  const [laborToggle, setLaborToggle] = useState('planned') // 実績：result 予定：planned
  const [month, setMonth] = useState(commonSearch.workDate.slice(0, 7))
  const [staff, setStaff] = useState()
  const [allUpdateOpen, setAllUpdateOpen] = useState(false)
  const [temps, setTemps] = useState() // 体温
  const grants = loginUserInfo.menuList &&
        loginUserInfo.menuList.find(menu => menu.functionMenuId === (FUNC.INDIVIDUAL_MONTHLY_LABOR))

  useEffect(() => {
    if (staff && staff.staffId && month && laborToggle) {
      const getTemperaturesByStaffId = async (staffId, from, to) => {
        const url = `/api/bodyTemperature/staff/${staffId}/${from}/${to}`
        const ret = await GetApi(url, {})
        if (ret.errorDetail) {
          exportErrorLog(ret)
          return null
        } else {
          setTemps(ret.data)
        }
      }
      // console.log(staff, month)
      dispatch(SetLoadSpinner(true))
      dispatch(SetDateRecords(null))
      dispatch(getIndividualMonthlyLabors(staff, month, laborToggle))
      const [beginningOfMonth, endOfMonth] = getFirstAndLastDate(month)
      dispatch(getLaborApproval('-', '-', staff, beginningOfMonth, endOfMonth))
      dispatch(getPlannedPersonalAttendance(staff.staffId, beginningOfMonth, endOfMonth))
      getTemperaturesByStaffId(staff.staffId, beginningOfMonth, endOfMonth)
    }
    if (!staff && loginUserInfo && loginUserInfo.staffId) {
      setStaff({ staffId: loginUserInfo.staffId, staffName: loginUserInfo.staffName })
      dispatch(SetCommonSearch({
        payCompanyId: loginUserInfo.companyId,
        payWarehouseId: loginUserInfo.warehouseId,
        payFloorId: loginUserInfo.floorId,
        payZoneId: loginUserInfo.zoneId,
        payWorkDate: commonSearch.workDate
        // month === DateTime.local().toFormat('yyyy-MM')
        //   ? commonSearch.workDate
        //   : month + '-01'
      }))
    }
    // eslint-disable-next-line
  }, [month, staff, laborToggle, loginUserInfo])

  // TODO 処理内容調査
  // 初期表示時のスクロールの場所
  useEffect(() => {
    if (document.getElementById('laborsWrap')) {
      const windowWidth = window.innerWidth
      const scrollDiffResult = 2420 - windowWidth + 35 //  - (nowTimeIndex * 15 - 20 * 15)
      document.getElementById('laborsWrap').scrollLeft = -scrollDiffResult // // 5時間前より表示
    }
  }, [document.getElementById('laborsWrap')])

  useEffect(() => {
    // 最初だけ必ず実行
    localStorage.setItem('processingFlg', false)
    document.title = '個人別月単位 | Core First'
  }, [])

  if (!grants) return null
  return (
    <LaborDiv>
      <Menu
        laborToggle={laborToggle}
        setLaborToggle={setLaborToggle}
        month={month}
        setMonth={setMonth}
        staff={staff}
        setStaff={setStaff}
        grants={grants}
        allUpdateOpen={allUpdateOpen}
        setAllUpdateOpen={setAllUpdateOpen}
      />
      <GanttChart
        title={`${month.substring(5, 7)}月`}
        month={month}
        grants={grants}
        staff={staff}
        laborToggle={laborToggle}
        allUpdateOpen={allUpdateOpen}
        temps={temps}
      />
      <Summary records={individualMonthly.dateRecords} />
      <SideMenu />
    </LaborDiv>
  )
}

export default IndividualMonthly
